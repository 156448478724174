import React from "react"

type GridInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  value: string | number
}

const GridInput: React.FC<GridInputProps> = ({ value, ...props }) => {
  return (
    <div className="w-full pr-[10px] h-[36px]">
      <input
        className="w-full h-full px-2 py-4 bg-transparent border outline-none outline-0 leading-base rounded-rounded inter-small-regular placeholder:text-grey-40 focus-within:shadow-input focus-within:border focus-within:border-violet-60"
        value={value}
        {...props}
        />
    </div>
  )
}

export default GridInput
