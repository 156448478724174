import { useAdminUpdateCollection } from "medusa-react"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../fundamentals/button"
import IconTooltip from "../../molecules/icon-tooltip"
import InputField from "../../molecules/input"
import Modal from "../../molecules/modal"
import Metadata, { MetadataField } from "../../organisms/metadata"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"

type CollectionModalProps = {
  onClose: () => void
  onSubmit: (values: any, metadata: MetadataField[]) => void
  isEdit?: boolean
  collection?: any
}

const EditCollectionModal: React.FC<CollectionModalProps> = ({
  onClose,
  onSubmit,
  isEdit = false,
  collection,
}) => {
  const { register, setValue, handleSubmit } = useForm()
  const [metadata, setMetadata] = useState<MetadataField[]>([])
  const updateCollection = useAdminUpdateCollection((collection as any).id)
  const notification = useNotification()
  if (isEdit && !collection) {
    throw new Error("Collection is required for edit")
  }

  useEffect(() => {
    register("title", { required: true })
    register("handle")
  }, [])

  useEffect(() => {
    if (isEdit && collection) {
      setValue("title", collection.title)
      setValue("handle", collection.handle)

      if (collection.metadata) {
        Object.entries(collection.metadata).map(([key, value]) => {
          const newMeta = metadata
          newMeta.push({ key, value })
          setMetadata(newMeta)
        })
      }
    }
  }, [collection, isEdit])

  const submit = (data: any) => {
    const payload: {
      title: string
      handle?: string
      metadata?: object
    } = {
      title: data.title,
      handle: data.handle,
    }

    if (metadata.length > 0) {
      const payloadMetadata = metadata
        .filter((m) => m.key && m.value) // remove empty metadata
        .reduce((acc, next) => {
          return {
            ...acc,
            [next.key]: next.value,
          }
        }, {})

      payload.metadata = payloadMetadata // deleting metadata will not work as it's not supported by the core
    }

    updateCollection.mutate(payload, {
      onSuccess: () => {
        onSubmit(data, metadata)
      },
      onError: (err) => notification("错误", getErrorMessage(err), "error"),
    })
  }

  return (
    <Modal handleClose={onClose}>
      <Modal.Body>
        <Modal.Header handleClose={onClose}>
          <div>
            <h1 className="inter-xlarge-semibold mb-2xsmall">
              {isEdit ? "编辑分类" : "新增分类"}
            </h1>
            {/* <p className="inter-small-regular text-grey-50">
              To create a collection, all you need is a title and a handle.
            </p> */}
          </div>
        </Modal.Header>
        <form onSubmit={handleSubmit(submit)}>
          <Modal.Content isLargeModal>
            <div>
              <h2 className="inter-base-semibold mb-base">详情</h2>
              <div className="flex items-center gap-x-base">
                <InputField
                  label="分类名"
                  required
                  placeholder="请输入分类名称"
                  name="title"
                  ref={register({ required: true })}
                />
                <InputField
                  label="标识符"
                  placeholder="请输入标识符"
                  name="handle"
                  prefix="/"
                  tooltip={
                    <IconTooltip content="产品分类URL，如果为空，将自动生成" />
                  }
                  ref={register}
                />
              </div>
            </div>
            {/* <div className="w-full mt-xlarge">
              <Metadata setMetadata={setMetadata} metadata={metadata} heading="元数据" />
            </div> */}
          </Modal.Content>
          <Modal.Footer>
            <div className="flex items-center justify-end w-full gap-x-xsmall">
              <Button
                variant="secondary"
                size="small"
                type="button"
                onClick={onClose}
              >
                取消
              </Button>
              <Button variant="primary" size="small">
                {`${isEdit ? "保存" : "发布"}分类`}
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default EditCollectionModal
