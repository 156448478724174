import React, { useState, useEffect } from "react"
import { Tree } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';
import { useForm, Controller } from "react-hook-form";
import Modal from '../../../../components/molecules/modal'
import Button from "../../../../components/fundamentals/button"
import InputField from "../../../../components/molecules/input"
import Medusa from '../../../../services/api'
import useNotification from "../../../../hooks/use-notification"
import { getErrorMessage } from "../../../../utils/error-messages"

const treeData: DataNode[] = [
  {
    title: '订单管理',
    key: '1'
  },
  {
    title: '薄荷糖抽奖管理',
    key: '2'
  },
  {
    title: '产品管理',
    key: '3'
  },
  {
    title: '顾客管理',
    key: '4'
  },
  {
    title: '平台管理',
    key: '5'
  },
  {
    title: '系统管理',
    key: '6'
  }
];

const AddRoleModal = ({ handleClose, refreshList, currRoleId }) => { 
  const [isLoading, setIsLoading] = useState(false)
  const [power, setPower] = useState([])
  const notification = useNotification()

  const { control, handleSubmit, formState: { errors }, register, reset } = useForm();

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    console.log('onCheck', checkedKeys, info);
    setPower(checkedKeys as [])
  };

  const onSubmit = (value) => { 
    setIsLoading(true)
    const mod = currRoleId === 0 || currRoleId ? 'modifyRole' : 'addRole'
    Medusa['role'][mod]({
      name: value.name,
      describe: value.desc,
      permissions: power
    }, currRoleId).then(res => { 
      if (res?.response?.status >= 400) {
        notification("失败", getErrorMessage(res), "error")
      } else { 
        handleSuccess()
      }
    }).catch(error => { 
      notification("失败", getErrorMessage(error), "error")
    }).finally(() => { 
      setIsLoading(false)
    })
  }

  const handleSuccess = () => { 
    notification("成功", `${currRoleId === 0 || currRoleId ? '修改' : '新增'}角色成功`, "success")
    initData()
    refreshList()
    handleClose()
  }

  const gainRoleDetails = (id) => { 
    Medusa['role']['getRoleDetails'](id).then(res => { 
      if (res.data) { 
        reset({
          name: res.data.data.name,
          desc: res.data.data.describe
        })
        setPower(res.data.data.permissions)
      }
    }).catch(error => { 
      notification("失败", getErrorMessage(error), "error")
    })
  }

  useEffect(() => { 
    if (currRoleId === 0 || currRoleId) { 
      gainRoleDetails(currRoleId)
    }
  }, [currRoleId])

  const initData = () => { 
    setPower([])
    reset({
      name: '',
      desc: ''
    })
  }
  return (
    <Modal handleClose={() => { }}>
      <Modal.Body>
        <Modal.Header handleClose={() => { initData(); handleClose()}}>
          <span className="inter-xlarge-semibold">{currRoleId === 0 || currRoleId ? '编辑': '新增'}角色</span>
        </Modal.Header>
        <Modal.Content>
          <div className="mt-2">
            <InputField
              label="名称"
              placeholder="请输入角色名称"
              required
              name="name"
              ref={register({required: true}) }
            />
          </div>
          <div className="mt-4">
            <InputField
              label="描述"
              name="desc"
              placeholder="请输入角色描述"
              ref={ register() }
            />
          </div>
          <div className="flex px-3 mt-4">
            <span className="text-grey-50">功能权限：</span>
            <div>
              <Tree
                checkable
                checkedKeys={ power }
                defaultCheckedKeys={[]}
                onSelect={onSelect}
                onCheck={onCheck}
                treeData={treeData}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex justify-end w-full h-8">
            <Button
              variant="ghost"
              className="justify-center w-32 mr-2 text-small"
              size="large"
              onClick={handleClose}
            >
              取消
            </Button>
            <Button
              loading={isLoading}
              size="large"
              className="justify-center w-32 text-small"
              variant="primary"
              onClick={handleSubmit(onSubmit)}
            >
              保存
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default AddRoleModal