import { useAdminRegion, useAdminTaxRates, useAdminUpdateRegion } from "medusa-react"
import clsx from "clsx"
import React, { useEffect, useState } from "react"
import BodyCard from "../../../components/organisms/body-card"
import { TaxRateType, PaginationProps } from "../../../types/shared"
import Input from "../../../components/molecules/input"
import { useForm } from "react-hook-form"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"
import { inputPrice } from '../../../utils/prices'

type TaxRate = {
  id: string
  name?: string
  rate: number | null
  code: string | null
  type: TaxRateType
}

const DEFAULT_PAGESIZE = 10

const TaxDetails = ({ id }) => {
  if (!id) {
    return null
  }
  const updateRegion = useAdminUpdateRegion(id)
  const [areaInfo, setAreaInfo] = useState({
    name: 'default',
    countries: '',
    rate: 0
  })

  const [pagination, setPagination] = useState<PaginationProps>({
    limit: DEFAULT_PAGESIZE,
    offset: 0,
  })

  const { register, reset, handleSubmit } = useForm()

  const { region, isLoading: regionIsLoading } = useAdminRegion(id)

  useEffect(() => {
    if ( !regionIsLoading && region ) {
      reset({ ...region })
      let countriesArr:any = []
      if (region.countries) { 
        countriesArr = region.countries.map(item => item.display_name)
      }
      setAreaInfo({
        ...areaInfo,
        name: region.name ?? 'default',
        countries: countriesArr.join(','),
        rate: region.tax_rate ?? 0,
      })
    }
  }, [regionIsLoading, region])
  const notification = useNotification()
  const onSave = (data) => { 
    const toSubmit = {
      tax_rate: parseFloat(data.rate),
      tax_code: '0',
    }
    updateRegion.mutate(toSubmit, {
      onSuccess: () => {
        notification("成功", "更新费率成功.", "success")
      },
      onError: (error) => {
        notification("失败", getErrorMessage(error), "error")
      },
    })
  }
  return (
    <>
      <BodyCard
        title="编辑"
        events={[{ label: "保存", onClick: handleSubmit(onSave) }]}
      >
        <div className="text-[#333] text-[14px] font-bold">支持国家地区</div>
        <div className="mt-[16px]">
          <Input
            name="name"
            label="名称"
            placeholder="地区名称"
            value={areaInfo.name}
            disabled
            className="w-full mb-0 mb-base"
          />
        </div>
        <div className="mt-[16px]">
          <Input
            name="country"
            label="国家"
            placeholder=""
            value={areaInfo.countries}
            disabled
            className="w-full mb-0 mb-base"
          />
        </div>
        <div className="text-[#333] text-[14px] font-bold mt-[20px]">从价关税</div>
        <div className="mt-[16px]">
          <form onSubmit={handleSubmit(onSave)}>
            <Input
              name="rate"
              label="税率 (%)"
              placeholder="请输入"
              defaultValue={areaInfo?.rate}
              ref={register({ min: 0, max: 100, required: true })}
              className="mb-base min-w-[335px] w-full"
              onInput={(val)=>val.value = inputPrice(val.value) }
            />
          </form>
        </div>
      </BodyCard>
      
    </>
  )
}

export default TaxDetails
