import React from "react";
import BodyCard from "../../../components/organisms/body-card"
import TableComponent from "./components/table";

const Bohetang = () => { 
  return (
    <div className="flex flex-col h-full grow">
      <div className="flex flex-col w-full grow">
        <BodyCard
          customHeader={
            <div className="inter-large-semibold gap-x-base text-[#333]">薄荷糖抽奖管理</div>
          }
        >
          <TableComponent />
        </BodyCard>
      </div>
    </div>
  )
}

export default Bohetang