import React from "react"
import { Controller } from "react-hook-form"
import BodyCard from "../../../../components/organisms/body-card"
import RadioGroup from "../../../../components/organisms/radio-group"
import { useProductForm } from "../form/product-form-context"
const Freight = ({ isEdit = false, product }) => { 
  const {
    control,
    register,
  } = useProductForm()
  return (
    <BodyCard title="运费" className="min-h-[100px]">
      <div className="mt-base">
        <div className="flex items-center">
          <span className="text-[#101010] text-[14px] mr-[100px]">是否免邮</span>
          <Controller
            control={control}
            name="mail_free"
            render={({ onChange, value }) => {
              return (
                <RadioGroup.Root
                  value={value}
                  onValueChange={onChange}
                  defaultValue={ product?.is_free_mail?'on':'off' }
                  className="flex items-center gap-4"
                >
                  <RadioGroup.SimpleItem
                  label="开启"
                  value="on"
                  />
                  <RadioGroup.SimpleItem
                    label="关闭"
                    value="off"
                  />
                </RadioGroup.Root>
              )
            }}
          >
            
          </Controller>
          
        </div>
        
      </div>
    </BodyCard>
  )
}

export default Freight