import React from "react"
import { formatAmountWithSymbol } from "../../../../utils/prices"

const OrderLine = ({ item, currencyCode }) => {
  return (
    <div className="flex justify-between mb-1 py-2 mx-[-5px] px-[5px] hover:bg-grey-5 rounded-rounded">
      <div className="flex justify-center space-x-4">
        <div className="flex h-[48px] w-[48px]">
          <img src={item.variant?.image_url || item.thumbnail} className="object-cover w-full rounded-rounded" />
        </div>
        <div className="flex flex-col justify-center">
          <span className="inter-small-regular text-grey-90 max-w-[225px] truncate">
            {item.title}
          </span>
          {item?.options && (
            Object.values(item?.options).map(item=>(
              <span className="inter-small-regular text-grey-50 inline-block py-[2px] px-[5px] bg-[#eee] mr-1">
                {item}
              </span>
            ))
          )}
          <span className="inter-small-regular text-grey-50">
            所属NFT型号：<span className="text-[#2a7dc9]">{ item.metadata?.nft_module?.tokenName || '--' }</span>
          </span>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex mr-3 small:space-x-2 medium:space-x-4 large:space-x-6">
          {/* <div className="inter-small-regular text-grey-50">
            {formatAmountWithSymbol({
              amount: item.unit_price,
              currency: currencyCode,
              digits: 2,
              tax: item.tax_lines,
            })}
          </div> */}
          <div className="inter-small-regular text-grey-50">
            x {item.quantity}
          </div>
          <div className="inter-small-regular text-grey-90">
            {formatAmountWithSymbol({
              // amount: item.unit_price * item.quantity,
              amount: item.unit_price,
              currency: currencyCode,
              digits: 2,
              tax: item.tax_lines,
            })}
          </div>
        </div>
        <div className="inter-small-regular text-grey-50">{currencyCode.toUpperCase()}</div>
      </div>
    </div>
  )
}

export default OrderLine
