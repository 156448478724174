import React, { useState, useEffect } from "react"
import moment from "moment"
import {
  useQuery
} from 'react-query'
import Table from "../molecules/table"
import EditIcon from "../fundamentals/icons/edit-icon"
import TrashIcon from "../fundamentals/icons/trash-icon"
import Medusa from '../../services/api'
import Spinner from "../atoms/spinner"
import DeletePrompt from "../organisms/delete-prompt"
import { getErrorMessage } from "../../utils/error-messages"
import useNotification from "../../hooks/use-notification"

const RoleTable = ({ roleChangeNum, setRoleChangeNum, editRole }) => {

  const [selectedRole, setSelectedRole] = useState(null)
  const notification = useNotification()
  const queryParams = {
    limit: 999,
    offset: 0
  }

  const queryRoleList = (query) => { 
    return Medusa['role']['getRoleList'](query)
  }

  // 查询列表数据
  const { refetch, isLoading, data } = useQuery(['adminRoleLs', queryParams], () => queryRoleList(queryParams))
  const handleCloseDelete = () => { 
    setSelectedRole(null)
  }
  useEffect(() => { 
    refetch()
  }, [roleChangeNum])

  return (
    <div className="w-full h-full mt-4 overflow-y-auto">
      <Table>
        <Table.Head>
          <Table.HeadRow>
            <Table.HeadCell className="w-[150px]">名称</Table.HeadCell>
            <Table.HeadCell>描述</Table.HeadCell>
            <Table.HeadCell>创建时间</Table.HeadCell>
            <Table.HeadCell>更新时间</Table.HeadCell>
          </Table.HeadRow>
        </Table.Head>
        {
          isLoading || !data?.data?.list ? (
            <div className="absolute flex items-center justify-center w-full h-full mt-10">
              <div className="">
                <Spinner size={"large"} variant={"secondary"} />
              </div>
            </div>
          ) : 
          (<Table.Body>
            {data?.data?.list?.map((role, index) => {
              return (
                <Table.Row
                  key={`role-${index}`}
                  color={"inherit"}
                  actions={[
                    {
                      label: "编辑角色",
                      onClick: () => { editRole(role.id) },
                      icon: <EditIcon size={20} />,
                    },
                    {
                      label: "移除角色",
                      variant: "danger",
                      onClick: () => { setSelectedRole(role) },
                      icon: <TrashIcon size={20} />,
                    },
                  ]}
                >
                  <Table.Cell className="pr-3">
                    <div className="text-grey-90">#{role.name}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="text-grey-90">{role.describe}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="text-grey-90">{moment(role.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="text-grey-90">{moment(role.updated_at).format("YYYY-MM-DD HH:mm:ss")}</div>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>)}
      </Table>
      {selectedRole&&(<DeletePrompt
        text={"你确定删除当前角色?"}
        heading={"删除用户"}
        successText={"角色被移除"}
        onDelete={() =>
          Medusa.role.deleteRole((selectedRole as any).id).then(() => {
            // notification("成功", "角色被移除", "success")
            refetch()
          })
        }
        handleClose={handleCloseDelete}
      />)}
    </div>
  )
}

export default RoleTable