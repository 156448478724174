import React, { useMemo, useState } from "react"
import {
  useQuery,
  useMutation,
} from 'react-query'
import moment from "moment"
import { usePagination, useTable } from "react-table"
import Table, { TablePagination } from "../../../../components/molecules/table"
import Spinner from "../../../../components/atoms/spinner"
import Medusa from '../../../../services/api'
import Tooltip from "../../../../components/atoms/tooltip"
import { OrderStatusTemplate } from '../../../../domain/orders/details/templates'
import { narrowTxt } from '../../../../utils/dealText'
import CopyToClipboard from '../../../../components/atoms/copy-to-clipboard'
import FilterTable from "../../../../components/filterTable"
import TimeRange from "./timeRange"


const TableComponent = () => { 
  // 初始化表头
  const columns = useMemo(() => ([
    {
      Header: '抽奖码',
      accessor: 'id',
    },
    {
      Header: '钱包地址',
      accessor: 'customer.wallet_address',
      Cell: ({ cell: { value } }) => (
        <>
          <span className="flex items-center">
            {value ? (
              <>
                {value.length > 14 ? (
                  <Tooltip content={value}>
                    <span className="mr-2 cursor-pointer">{narrowTxt(value)}</span>
                  </Tooltip>
                ): (
                  <span className="mr-2 cursor-pointer">{value}</span>
                ) }
                <CopyToClipboard
                  value={value}
                  showValue={false}
                  iconSize={14}
                />
              </>
            ):(<span>--</span>)}
            </span>
        </>
      ),
    },
    {
      Header: '订单号',
      accessor: 'order_id',
    },
    {
      Header: '订单状态',
      accessor: 'order',
      Cell: ({ cell: { value }, index }) => (
        <>{OrderStatusTemplate({
          fulfillmentStatus: value.fulfillment_status,
          paymentStatus: value.payment_status,
          orderStatus: value.status,
        })}</>
      ),
    },
    {
      Header: '邮箱地址',
      accessor: 'email',
    },
    {
      Header: '生成时间',
      accessor: 'created_at',
      Cell: ({ cell: { value }, index }) => (
        <>
          {moment(value).format("YYYY-MM-DD HH:mm:ss")}
        </>
      ),
    }
  ]), [])
  
  // 初始化筛选项值
  const flitterOpt = [
    {
      label: '抽奖码',
      vkey: 'drawCode',
      type: 'input'
    },
    {
      label: '钱包地址',
      vkey: 'wallet',
      type: 'input'
    },
    {
      label: '订单号',
      vkey: 'orderId',
      type: 'input'
    },
    {
      label: '邮箱地址',
      vkey: 'email',
      type: 'input'
    }
  ]

  // 请求列表所需要携带的参数
  const [logsLsParams, setLogsLsParams] = useState({
    offset: 0,
    limit: 15,
    drawCode: '',
    wallet: '',
    orderId: '',
    email: ''
  })

  // 筛选项改变修改 logsLsParams
  const changeFiltter = (val) => { 
    const opt = {}
    for (let key in val) { 
      opt[key] = val[key]
    }
    setLogsLsParams({
      ...logsLsParams,
      ...opt
    })
  }
  // 返回一个promise函数 
  const getlogsLs = (query) => { 
    return Medusa['lotteryManage']['getMintDrawLogs'](query)
  }
  // 查询列表数据
  const { refetch, isLoading, data } = useQuery(['projects', logsLsParams], ()=>getlogsLs(logsLsParams))
  // 初始化表格
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: data?.data?.list || [],
      manualPagination: true,
      initialState: {
        pageSize: logsLsParams.limit,
        pageIndex: logsLsParams.offset / logsLsParams.limit,
      },
      pageCount: Math.ceil(data?.data?.count! / logsLsParams.limit),
      autoResetPage: false,
    },
    usePagination
    )
  // 下一页
  const handleNext = () => {
    if (logsLsParams.offset / logsLsParams.limit < pageCount - 1) {
      setLogsLsParams({
        ...logsLsParams,
        offset: logsLsParams.offset + logsLsParams.limit
      })
      nextPage()
    }
  }
  // 上一页
  const handlePrev = () => {
    if (logsLsParams.offset>0) {
      setLogsLsParams({
        ...logsLsParams,
        offset: logsLsParams.offset - logsLsParams.limit
      })
      previousPage()
    }
  }
  return (
    <div className="flex flex-col justify-between w-full h-full overflow-y-auto">
      <div>
        <FilterTable fopt={flitterOpt} submitChange={changeFiltter}></FilterTable>
        <TimeRange></TimeRange>
        <Table {...getTableProps()}>
          <Table.Head>
            {headerGroups?.map((headerGroup) => (
              <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((col, index) => (
                  <Table.HeadCell className="w-[100px]" {...col.getHeaderProps()} key={ index }>
                    {col.render("Header")}
                  </Table.HeadCell>
                ))}
              </Table.HeadRow>
            ))}
          </Table.Head>
          { !(isLoading || !data?.data?.list) && (
            <Table.Body {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <Table.Row
                    color={"inherit"}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <Table.Cell {...cell.getCellProps()} key={ index }>
                          {cell.render("Cell", { index })}
                        </Table.Cell>
                      )
                    })}
                  </Table.Row>
                )
              })}
            </Table.Body>
          )}
        </Table>
        {
          isLoading || !data?.data?.list && (
            <div className="absolute flex items-center justify-center w-full h-full mt-10">
              <div className="">
                <Spinner size={"large"} variant={"secondary"} />
              </div>
            </div>
          )
        }
      </div>
      <TablePagination
        count={data?.data?.count!}
        limit={logsLsParams.limit}
        offset={logsLsParams.offset}
        pageSize={logsLsParams.offset + rows.length}
        title=""
        currentPage={logsLsParams.offset / logsLsParams.limit + 1}
        pageCount={pageCount}
        nextPage={handleNext}
        prevPage={handlePrev}
        hasNext={logsLsParams.offset / logsLsParams.limit < pageCount - 1}
        hasPrev={logsLsParams.offset > 0}
      />
    </div>
  )
}

export default TableComponent