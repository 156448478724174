import { useAdminStore } from "medusa-react"
import { Col, Row, Image } from 'antd';
import React, { useEffect, useState } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { PlusOutlined } from '@ant-design/icons';
import Checkbox from "../../../../components/atoms/checkbox"
import Button from "../../../../components/fundamentals/button"
import PlusIcon from "../../../../components/fundamentals/icons/plus-icon"
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon"
import IconTooltip from "../../../../components/molecules/icon-tooltip"
import Input from "../../../../components/molecules/input"
import Modal from "../../../../components/molecules/modal"
import Select from "../../../../components/molecules/select"
import RadioGroup from "../../../../components/organisms/radio-group"
import CurrencyInput from "../../../../components/organisms/currency-input"
import FileUploadField from "../../../../components/atoms/file-upload-field"
import { convertEmptyStringToNull } from "../../../../utils/convert-empty-string-to-null"
import { countries as countryData } from "../../../../utils/countries"
import { focusByName } from "../../../../utils/focus-by-name"
import usePricesFieldArray from "../../product-form/form/usePricesFieldArray"
import morentupian from "../../../../assets/images/morentupian.png"
import Medusa from "../../../../services/api"
import useNotification from "../../../../hooks/use-notification"
import { getErrorMessage } from "../../../../utils/error-messages"

const defaultVariant = {
  prices: [] as any,
  origin_country: "",
  options: [] as any,
  image_url: ""
}

const VariantEditor = ({
  variant = defaultVariant,
  onSubmit,
  onCancel,
  title,
  optionsMap,
}) => {
  const countryOptions = countryData.map((c) => ({
    label: c.name,
    value: c.alpha2.toLowerCase(),
  }))
  const notification = useNotification()
  const { store } = useAdminStore()
  const [selectedCountry, setSelectedCountry] = useState(() => {
    const defaultCountry = variant.origin_country
      ? countryOptions.find((cd) => cd.label === variant.origin_country)
      : null
    return defaultCountry || null
  })

  const { control, register, reset, watch, handleSubmit } = useForm({
    defaultValues: variant,
  })
  const {
    fields: prices,
    appendPrice,
    deletePrice,
    availableCurrencies,
  } = usePricesFieldArray(
    store?.currencies.map((c) => c.code) || [],
    {
      control,
      name: "prices",
      keyName: "indexId",
    },
    {
      defaultAmount: 1000,
      defaultCurrencyCode:
        store?.default_currency.code || store?.currencies[0].code || "usd",
    }
  )

  const { fields } = useFieldArray({
    control,
    name: "options",
    keyName: "indexId",
  })
  console.log('-----options-------', fields)
  useEffect(() => {
    reset({
      ...variant,
      options: Object.values(optionsMap),
      prices: variant?.prices.map((p) => ({
        price: { ...p },
      })),
    })
  }, [variant, store])

  const [images, setImages] = React.useState(() => variant.image_url ? ({url: variant.image_url}): null)
  const appendImage = (image) => {
    setImages(image)
  }

  const isType = (val: unknown, type: string) => {
    return toString.call(val) === `[object ${type}]`;
  }

  const handleSave = async (data) => {
    if (!data.prices) {
      focusByName("add-price")
      return
    }

    let uploadedImgUrl:any = ''
    if (images?.url.startsWith('blob')) {
      uploadedImgUrl = await Medusa.uploads
        .create([(images as any).nativeFile])
        .then(({ data }) => {
          const uploaded = data.uploads.map(({ url }) => url)
          return uploaded[0]
        })
        .catch((err) => {
          notification("Error uploading images", getErrorMessage(err), "error")
          return
        })
    } else {
      uploadedImgUrl = images?.url || null
    }

    if (!data.title) {
      data.title = data?.options?.map((o) => o.value).join(" / ")
    }

    data.prices = data.prices.map(({ price: { currency_code, amount } }) => ({
      currency_code,
      amount: Math.round(amount),
    }))
    data.options = data?.options?.map((option) => ({ ...option }))

    data.origin_country = selectedCountry?.label
    data.inventory_quantity = parseInt(data.inventory_quantity)
    data.weight = data?.weight ? parseInt(data.weight, 10) : undefined
    data.height = data?.height ? parseInt(data.height, 10) : undefined
    data.width = data?.width ? parseInt(data.width, 10) : undefined
    data.length = data?.length ? parseInt(data.length, 10) : undefined
    data.image_url = uploadedImgUrl
    if (!isType(data.is_irl, 'Boolean')) {
      data.is_irl = data.is_irl === 'on' ? true : false
    }
    const cleaned = convertEmptyStringToNull(data)
    cleaned.allow_backorder = false
    cleaned.manage_inventory = true
    onSubmit(cleaned)
  }

  watch(["manage_inventory", "allow_backorder"])

  const variantTitle = variant?.options
    .map((opt) => opt?.value || "")
    .join(" / ")

  return (
    <Modal handleClose={() => { }}>
      <Modal.Body>
        <Modal.Header handleClose={onCancel}>
          <h2 className="inter-xlarge-semibold">
            {title}{" "}
            {variantTitle && (
              <span className="text-grey-50 inter-xlarge-regular">
                ({variantTitle})
              </span>
            )}
          </h2>
        </Modal.Header>
        <Modal.Content>
          <div className="mb-8">
            <label
              tabIndex={0}
              className="flex items-center mb-4 inter-base-semibold gap-xsmall"
            >
              {"基础信息"}
            </label>

            <div className="grid grid-cols-1 gap-y-small">
              <Input label="标题" name="title" ref={register} placeholder="请输入" />
              {fields.map((field, index) => (
                <div key={field.indexId}>
                  <Input
                    ref={register({ required: true })}
                    name={`options[${index}].value`}
                    required={true}
                    label={field.title}
                    defaultValue={field.value}
                    placeholder="请输入"
                  />
                  <input
                    ref={register()}
                    type="hidden"
                    name={`options[${index}].option_id`}
                    defaultValue={field.option_id}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="mb-8">
            <label
              tabIndex={0}
              className="flex items-center mb-4 inter-base-semibold gap-xsmall"
            >
              {"规格图"}
            </label>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <FileUploadField
                  onFileChosen={(files) => {
                    const file = files[0]
                    const url = URL.createObjectURL(file)
                    appendImage({
                      url,
                      name: file.name,
                      size: file.size,
                      nativeFile: file,
                    })
                  }}
                  text={
                    <div className="flex flex-col items-center">
                      <PlusOutlined className="text-[30px] text-[#9e9e9e] mb-2" />
                      <span>
                        将您的素材放到这里, 或{" "}
                        <span className="text-violet-60">点击上传</span>
                      </span>
                    </div>
                  }
                  filetypes={["png", "jpg", "jpeg"]}
                  className="py-large h-w-ration1"
                />
              </Col>
              <Col className="gutter-row" span={12}>
                <img className="block w-[100%] h-w-ration1" src={images?(images as any).url:morentupian} alt="" />
              </Col>
            </Row>
          </div>
          <div className="mb-8">
            <label
              tabIndex={0}
              className="flex items-center mb-4 inter-base-semibold"
            >
              {"价格"}
              <span className="text-rose-50 mr-xsmall">*</span>
              {/* <IconTooltip content={"Variant prices"} /> */}
            </label>

            <div className="grid grid-cols-1 gap-y-xsmall">
              {prices.map((field, index) => (
                <div className="flex items-center" key={field.indexId}>
                  <div className="w-full">
                    <Controller
                      control={control}
                      key={field.indexId}
                      name={`prices[${index}].price`}
                      ref={register()}
                      defaultValue={field.price}
                      render={({ onChange, value }) => {
                        let codes = availableCurrencies
                        if (value?.currency_code) {
                          codes = [value?.currency_code, ...availableCurrencies]
                        }
                        codes.sort()
                        return (
                          <CurrencyInput
                            currencyCodes={codes}
                            currentCurrency={value?.currency_code}
                            size="medium"
                            readOnly={index === 0}
                            onChange={(code) =>
                              onChange({ ...value, currency_code: code })
                            }
                          >
                            <CurrencyInput.AmountInput
                              label="价格"
                              onChange={(amount) =>
                                onChange({ ...value, amount })
                              }
                              amount={value?.amount}
                            />
                          </CurrencyInput>
                        )
                      }}
                    />
                  </div>

                  <Button
                    variant="ghost"
                    size="small"
                    className="ml-8 w-8 h-8 mr-2.5 text-grey-40 hover:text-grey-80 transition-colors"
                    onClick={deletePrice(index)}
                  >
                    <TrashIcon />
                  </Button>
                </div>
              ))}
            </div>
            {prices.length < 1 && (
              <Button
                className="mt-4"
                onClick={appendPrice}
                size="small"
                variant="ghost"
                name="add-price"
                disabled={availableCurrencies?.length === 0}
              >
                <PlusIcon size={20} /> 添加价格
              </Button>
            )}
          </div>
          <div className="mb-8">
            <label className="flex items-center inter-base-semibold gap-xsmall">
              {"IRL标识"}
            </label>
            <div className="flex items-center px-3 py-6 border mt-base bg-grey-5 border-grey-20 rounded-rounded">
              <span className="text-[#101010] text-[14px] mr-[100px]">是否支持IRL</span>
              <Controller
                control={control}
                name="is_irl"
                ref={register()}
                render={({ onChange, value }) => {
                  console.log(value)
                  return (
                    <RadioGroup.Root
                      value={toString.call(value) === `[object Boolean]`?value?'on':'off':value}
                      onValueChange={onChange}
                      defaultValue={ variant?.is_irl?'on':'off' }
                      className="flex items-center gap-4"
                    >
                      <RadioGroup.SimpleItem
                      label="开启"
                      value="on"
                      />
                      <RadioGroup.SimpleItem
                        label="关闭"
                        value="off"
                      />
                    </RadioGroup.Root>
                  )
                }}
              />
            </div>
          </div>
          <div className="mb-8">
            <label className="flex items-center inter-base-semibold gap-xsmall">
              {"库存"}
              {/* <IconTooltip content={"Stock and inventory information"} /> */}
            </label>
            <div className="grid w-full grid-cols-1 mt-4 medium:grid-cols-2 gap-y-base gap-x-xsmall">
              <Input label="SKU" name="sku" placeholder="SKU" ref={register} />
              <Input label="EAN" name="ean" placeholder="EAN" ref={register} />
              <Input
                label="库存数量"
                name="inventory_quantity"
                placeholder="100"
                type="number"
                min={0}
                ref={register}
                onInput={(val)=>val.value = val.value.replace(/^(0+)|[^\d]+/g, '') }
              />

              <Input
                label="UPC 条码"
                name="barcode"
                placeholder="条码"
                ref={register}
              />
            </div>

            {/* <div className="flex items-center mt-6 gap-x-large">
              <div className="flex item-center gap-x-1.5">
                <Checkbox
                  name="manage_inventory"
                  label="Manage Inventory"
                  ref={register}
                />
                <IconTooltip
                  content={
                    "When checked Medusa will regulate the inventory when orders and returns are made."
                  }
                />
              </div>
              <div className="flex item-center gap-x-1.5">
                <Checkbox
                  name="allow_backorder"
                  ref={register}
                  label="Allow backorders"
                />
                <IconTooltip
                  content={
                    "When checked the product will be available for purchase despite the product being sold out."
                  }
                />
              </div>
            </div> */}
          </div>

          <div className="mb-8">
            <label className="flex items-center inter-base-semibold gap-xsmall">
              产品信息
              {/* <IconTooltip content={"Variant dimensions"} /> */}
            </label>
            <div className="grid w-full grid-cols-1 mt-4 medium:grid-cols-2 gap-y-base gap-x-xsmall">
              <Input
                label="高度"
                placeholder="产品高度"
                name="height"
                ref={register}
              />
              <Input
                label="宽度"
                placeholder="产品宽度"
                name="width"
                ref={register}
              />
              <Input
                label="长度"
                name="length"
                placeholder="产品长度"
                ref={register}
              />
              <Input
                label="重量"
                name="weight"
                placeholder="产品重量"
                ref={register}
              />
            </div>
          </div>
          <div className="mb-8">
            <label className="flex items-center inter-base-semibold gap-xsmall">
              海关
              {/* <IconTooltip content={"Variant customs information"} /> */}
            </label>
            <div className="grid w-full grid-cols-1 mt-4 medium:grid-cols-2 gap-y-base gap-x-xsmall">
              <Input
                label="中间码"
                placeholder="中间码"
                name="mid_code"
                ref={register}
              />
              <Input
                label="HS编码"
                placeholder="HS编码"
                name="hs_code"
                ref={register}
              />
              <Select
                enableSearch
                label={"发源地"}
                options={countryOptions}
                value={selectedCountry}
                onChange={setSelectedCountry}
              />
              <Input
                label="材料"
                name="material"
                placeholder="材料"
                ref={register}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex justify-end w-full gap-x-base">
            <Button
              className="w-[127px]"
              onClick={onCancel}
              size="small"
              variant="ghost"
            >
              取消
            </Button>
            <Button
              onClick={handleSubmit(handleSave)}
              type="submit"
              className="w-[127px]"
              size="small"
              variant="primary"
            >
              保存
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default VariantEditor
