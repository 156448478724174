import React from "react"
import { useForm } from "react-hook-form"

import Button from "../../../components/fundamentals/button"
import Textarea from "../../../components/molecules/textarea"
import Modal from "../../../components/molecules/modal"
import { useAdminUpdateOrder } from "medusa-react"
import useNotification from "../../../hooks/use-notification";
import { getErrorMessage } from "../../../utils/error-messages";

type RemarkModalProps = {
  handleClose: () => void
  handleSave: ({ remark: string }) => any
  remark?: string
  id: any
}

const RemarkModal: React.FC<RemarkModalProps> = ({
  id,
  remark,
  handleClose,
  handleSave,
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: { remark },
  })
  const notification = useNotification();
  const updateOrder = useAdminUpdateOrder(id)
  const submit = (data) => {
    const updateObj = data.remark ? { remark: data.remark } : {}
    updateOrder.mutate(updateObj, {
      onSuccess: () => {
        notification(
          "成功",
          "更新订单备注成功",
          "success"
        )
        handleClose()
      },
      onError: (err) => {
        handleClose();
        notification("失败", getErrorMessage(err), "error");
      },
    })
  }

  return (
    <Modal handleClose={handleClose}>
      <Modal.Body>
        <Modal.Header handleClose={handleClose}>
          <span className="inter-xlarge-semibold">修改备注</span>
        </Modal.Header>
        <Modal.Content>
          <div className="space-y-4">
            <div className="flex mt-4 space-x-4">
              <Textarea
                name="remark"
                id="remark"
                label="备注"
                placeholder="请输入备注"
                className="row-span-full"
                rows={4}
                ref={register}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex justify-end w-full h-8">
            <Button
              variant="ghost"
              className="justify-center w-32 mr-2 text-small"
              size="large"
              onClick={handleClose}
            >
              取消
            </Button>
            <Button
              size="large"
              className="justify-center w-32 text-small"
              variant="primary"
              onClick={handleSubmit(submit)}
            >
              保存
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default RemarkModal
