import React, { useEffect } from "react";
import { Button, Modal, Form, Input } from 'antd';
import Medusa from '../../../../services/api'

const UpdateFulfillment = ({ isModalVisible, handleOk, handleCancel, order }) => {
  interface formDataModel { 
    company: string;
    orderNo: string;
  }
  const [form] = Form.useForm<formDataModel>()
  useEffect(() => { 
    form.setFieldsValue({
      company: order?.fulfillments[0]?.metadata?.logistics_company,
      orderNo: order?.fulfillments[0]?.tracking_links[0]?.tracking_number,
    })
  }, [order])
  const comfireModify = () => { 
    form.submit();
  }
  const onFinish = (value) => {
    Medusa['orders']['updateFulfillments'](order?.fulfillments[0].id, {
      logistics_company:value.company,
      tracking_number: value.orderNo
    }).then(res => {
      order.fulfillments[0].metadata.logistics_company = value.company
      order.fulfillments[0].tracking_links[0].tracking_number = value.orderNo
      handleOk()
    })
  }
  return (
    <>
      <Modal
        title="修改物流信息"
        visible={isModalVisible}
        onOk={comfireModify}
        cancelText="取消"
        okText="确认"
        onCancel={handleCancel}>
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="物流公司"
            name="company"
            rules={[{ required: true, message: '请输入物流公司名称!' }]}
          >
            <Input placeholder="请输入物流公司名称" />
          </Form.Item>
          <Form.Item
            label="物流单号"
            name="orderNo"
            rules={[{ required: true, message: '请输入物流单号!' }]}
          >
            <Input placeholder="请输入物流单号" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateFulfillment