import { RouteComponentProps, Router } from "@reach/router"
import React, { useState } from "react"

import BodyCard from "../../components/organisms/body-card"
import CustomerTable from "../../components/templates/customer-table"
import CustomerGroups from "./groups"
import Details from "./details"
import CustomersPageTableHeader from "./header"

const CustomerIndex: React.FC<RouteComponentProps> = () => {
  return (
    <div className="flex flex-col h-full grow">
      <div className="flex flex-col w-full grow">
        <BodyCard
          customHeader={
            // <CustomersPageTableHeader activeView="customers" />
            <div className="inter-large-semibold gap-x-base text-[#333]">顾客管理</div>
          }
        >
          <CustomerTable />
        </BodyCard>
      </div>
    </div>
  )
}

const Customers = () => {
  return (
    <Router>
      <CustomerIndex path="/" />
      <CustomerGroups path="/groups/*" />
      <Details path=":id" />
    </Router>
  )
}

export default Customers
