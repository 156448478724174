import { useParams } from "@reach/router"
import { navigate } from "gatsby"
import {
  useAdminCollections,
  useAdminDeleteProduct,
  useAdminProductTypes,
  useAdminUpdateProduct,
} from "medusa-react"
import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
// import useState from "storybook-addon-state"
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon"
import Input from "../../../../components/molecules/input"
import Select from "../../../../components/molecules/select"
import StatusSelector from "../../../../components/molecules/status-selector"
import TagInput from "../../../../components/molecules/tag-input"
import Textarea from "../../../../components/molecules/textarea"
import BodyCard from "../../../../components/organisms/body-card"
import RadioGroup from "../../../../components/organisms/radio-group"
import useImperativeDialog from "../../../../hooks/use-imperative-dialog"
import useNotification from "../../../../hooks/use-notification"
import Medusa from "../../../../services/api"
import { getErrorMessage } from "../../../../utils/error-messages"
import {
  useProductForm
} from "../form/product-form-context"


interface CollectOptions {
  id:number
  name:string
  address:string
}
interface Options {
  label: string
  value: string
}

const General = ({ showViewOptions = true, isEdit = false, product }) => {
  const {
    register,
    control,
    setViewType,
    viewType,
    setValue,
  } = useProductForm()

  const [isNft, setIsNft] = useState('yes')
  const [collectOptions, setCollectOptions] = useState<Options[]>([])

  const { product_types } = useAdminProductTypes(undefined, { cacheTime: 0 })
  const { collections } = useAdminCollections()

  const typeOptions =
    product_types?.map((tag) => ({ label: tag.value, value: tag.id })) || []
  const collectionOptions =
    collections?.map((collection) => ({
      label: collection.title,
      value: collection.id,
    })) || []

  const setNewType = (value: string) => {
    const newType = {
      label: value,
      value,
    }

    typeOptions.push(newType)
    setValue("type", newType)

    return newType
  }

  useEffect(() => {
    if (collectOptions.length) return
    const getCollectOptions = async () => {
      const res = await Medusa.products.metadataCollect.list()
      const arr:CollectOptions[] = res.data?.collects || []
      const data = arr?.map(v => ({label:v.name, value:`${v.id}_${v.name}_${v.address}`}))
      setCollectOptions(data)
    }
    getCollectOptions()
  }, [])

  return (
    <GeneralBodyCard
      isEdit={isEdit}
      product={product}
      title="基础信息"
      subtitle=""
    >
      <div className="mt-large">
        <div className="flex gap-8 mb-base">
          <Input
            id="name"
            label="产品名"
            name="title"
            placeholder="请输入产品名称"
            required
            ref={register({
              required: "产品名称必须填写",
              minLength: 1,
              pattern: /(.|\s)*\S(.|\s)*/,
            })}
          />
          <Input
            // tooltipContent="Handles are human friendly unique identifiers that are appropriate for URL slugs."
            label="标识符"
            name="handle"
            placeholder=""
            prefix="/"
            ref={register()}
          />
        </div>
        <div className="grid grid-cols-2 grid-row-4 gap-x-8 gap-y-4 mb-large">
          <div className="grid grid-cols-1 gap-y-4">

            <div className="flex flex-col w-full p-3 border bg-grey-5 inter-base-regular h-18 cursor-text border-grey-20 focus-within:shadow-input focus-within:border-violet-60 rounded-rounded">
              <div className="flex items-center w-full inter-small-semibold text-grey-50">
                <label>NFT定制商品</label>
                <div className="text-rose-50">*</div>
              </div>
              <Controller
                control={control}
                name="is_nft"
                render={({ onChange, value }) => {
                  return (
                    <RadioGroup.Root
                      value={toString.call(value) === `[object Boolean]`?value?'yes':'no':value}
                      onValueChange={onChange}
                      defaultValue={ product?.is_nft?'yes':'no' }
                      className="flex items-center gap-4 mt-3"
                    >
                      <RadioGroup.SimpleItem
                        label="是"
                        value={'yes'}
                      />
                      <RadioGroup.SimpleItem
                        label="否"
                        value={'no'}
                      />
                    </RadioGroup.Root>
                  )
                }}
              />
            </div>

            <div>
              <Textarea
                name="description"
                id="description"
                label="描述"
                placeholder="请输入产品描述"
                className="row-span-full"
                rows={8}
                ref={register}
              />
            </div>

            <div>
              <Textarea
                name="details"
                id="details"
                label="产品详情"
                placeholder="请输入产品详情"
                className="row-span-full"
                rows={8}
                ref={register({
                  required: "产品详情必须填写",
                  minLength: 1,
                  pattern: /(.|\s)*\S(.|\s)*/,
                })}
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-y-4">
            <div>
              <Controller
                as={Select}
                control={control}
                label="分类"
                name="collection"
                placeholder="请选择分类..."
                options={collectionOptions}
                clearSelected
              />
            </div>
            <div>
              <Controller
                control={control}
                name="type"
                render={({ value, onChange }) => {
                  return (
                    <Select
                      label="类型"
                      placeholder="请选择类型..."
                      options={typeOptions}
                      onChange={onChange}
                      value={value}
                      isCreatable
                      onCreateOption={(value) => {
                        return setNewType(value)
                      }}
                      clearSelected
                    />
                  )
                }}
              />
            </div>
            {/* <div>
              <Controller
                control={control}
                name="productMetadataCollect"
                render={({ value, onChange }) => {
                  return (
                    <Select
                      label="合集"
                      placeholder="请选择合集..."
                      options={collectOptions}
                      onChange={onChange}
                      value={value}
                      clearSelected
                    />
                  )
                }}
              />
            </div> */}
            <div>
              <Controller
                name="tags"
                render={({ onChange, value }) => {
                  return (
                    <TagInput
                      label="标签 (以逗号分割)"
                      placeholder=""
                      onChange={onChange}
                      values={value || []}
                    />
                  )
                }}
                control={control}
              />
            </div>

            <div>
              <Textarea
                name="mail_explain"
                id="mail_explain"
                label="邮寄和退换货说明"
                placeholder="请输入邮寄和退换货说明"
                className="row-span-full"
                rows={8}
                ref={register}
              />
            </div>
          </div>
        </div>
        {/* 商品是否享受折扣 */}
        {/* <div className="flex item-center gap-x-1.5 mb-xlarge">
          <Checkbox name="discountable" ref={register} label="Discountable" />
          <IconTooltip
            content={
              "When unchecked discounts will not be applied to this product"
            }
          />
        </div> */}
        {/* {showViewOptions && (
          <RadioGroup.Root
            value={viewType}
            onValueChange={setViewType}
            className="flex items-center gap-4 mt-xlarge"
          >
            <RadioGroup.SimpleItem
              label="Simple product"
              value={SINGLE_PRODUCT_VIEW}
            />
            <RadioGroup.SimpleItem
              label="Product with variants"
              value={VARIANTS_VIEW}
            />
          </RadioGroup.Root>
        )} */}
      </div>
    </GeneralBodyCard>
  )
}

const GeneralBodyCard = ({ isEdit, product, ...props }) => {
  const params = useParams()
  const dialog = useImperativeDialog()
  const notification = useNotification()
  const updateProduct = useAdminUpdateProduct(params?.id)
  const deleteProduct = useAdminDeleteProduct(params?.id)

  const onDelete = async () => {
    const shouldDelete = await dialog({
      heading: "删除产品",
      text: "你确定要删除该产品",
    })
    if (shouldDelete) {
      deleteProduct.mutate(undefined, {
        onSuccess: () => {
          notification("成功", "删除产品成功", "success")
          navigate("/a/products/")
        },
        onError: (err) => {
          notification("Ooops", getErrorMessage(err), "error")
        },
      })
    }
  }

  const onStatusChange = async () => {
    const newStatus = product?.status === "published" ? "draft" : "published"
    updateProduct.mutate(
      {
        status: newStatus,
      },
      {
        onSuccess: () => {
          const pastTense = newStatus === "published" ? "发布" : "未发布"
          notification(
            "成功",
            `${pastTense} 产品成功`,
            "success"
          )
        },
        onError: (err) => {
          notification("Ooops", getErrorMessage(err), "error")
        },
      }
    )
  }

  const actionables = [
    {
      label: "删除产品",
      onClick: onDelete,
      variant: "danger" as const,
      icon: <TrashIcon />,
    },
  ]

  return (
    <BodyCard
      actionables={isEdit ? actionables : undefined}
      forceDropdown
      status={
        isEdit ? (
          <StatusSelector
            isDraft={product?.status === "draft"}
            activeState="发布"
            draftState="未发布"
            onChange={onStatusChange}
          />
        ) : undefined
      }
      {...props}
    />
  )
}

export default General
