import { Router } from "@reach/router";
import React, { useContext } from "react";
import SettingsCard from "../../components/atoms/settings-card";
import CoinsIcon from "../../components/fundamentals/icons/coins-icon";
import CrosshairIcon from "../../components/fundamentals/icons/crosshair-icon";
import DollarSignIcon from "../../components/fundamentals/icons/dollar-sign-icon";
import HappyIcon from "../../components/fundamentals/icons/happy-icon";
import MailIcon from "../../components/fundamentals/icons/mail-icon";
import MapPinIcon from "../../components/fundamentals/icons/map-pin-icon";
import TaxesIcon from "../../components/fundamentals/icons/taxes-icon";
import TruckIcon from "../../components/fundamentals/icons/truck-icon";
import UsersIcon from "../../components/fundamentals/icons/users-icon";
import SettingsOverview from "../../components/templates/settings-overview";
import Currencies from "./currencies";
import Details from "./details";
import PersonalInformation from "./personal-information";
import Regions from "./regions";
import RegionDetails from "./regions/details";
import NewRegion from "./regions/new";
import ReturnReasons from "./return-reasons";
import Taxes from "./taxes";
import Users from "./users";
import { LayeredModalContext } from "../../components/molecules/modal/layered-modal";
import MemberTable from "../../components/templates/member-table";
import RoleTable from "../../components/templates/role-table";
import PrivateRoute from "../../components/private-route";

const SettingsIndex = () => {
  const { t } = useContext(LayeredModalContext);
  return (
    <SettingsOverview>
      <SettingsCard
        heading={t("settings.currencyCardTit")}
        description={t("settings.currencyCardDesc")}
        icon={<CoinsIcon />}
        to={`/a/settings/currencies`}
      />
      <SettingsCard
        heading={t("settings.regionsCardTit")}
        description={t("settings.regionsCardDesc")}
        icon={<MapPinIcon />}
        to={`/a/settings/regions`}
      />
      <SettingsCard
        heading={t("settings.taxCardTit")}
        description={t("settings.taxCardDesc")}
        icon={<TaxesIcon />}
        to={`/a/settings/taxes`}
      />
      <SettingsCard
        heading={t("settings.teamCardTit")}
        description={t("settings.teamCardDesc")}
        icon={<UsersIcon />}
        to={`/a/settings/team`}
      />
      <SettingsCard
        heading={t("settings.personalCardTit")}
        description={t("settings.personalCardDesc")}
        icon={<HappyIcon />}
        to={`/a/settings/personal-information`}
      />
      {/* <SettingsCard
        heading={"Store Details"}
        description={"Manage your business details"}
        icon={<CrosshairIcon />}
        to={`/a/settings/details`}
      />
      <SettingsCard
        heading={"Shipping"}
        description={"Manage shipping profiles"}
        icon={<TruckIcon />}
        to={`/a/settings/shipping-profiles`}
        disabled={true}
      />
      <SettingsCard
        heading={"Return Reasons"}
        description={"Manage Order settings"}
        icon={<DollarSignIcon />}
        to={`/a/settings/return-reasons`}
      />
      <SettingsCard
        heading={"hello@medusajs.com"}
        description={"Can’t find the answers you’re looking for?"}
        icon={<MailIcon />}
        externalLink={"mailto: hello@medusajs.com"}
      /> */}
    </SettingsOverview>
  );
};

const Settings = () => (
  <Router className="h-full">
    {/* <SettingsIndex path="/" />
    <Details path="details" />

    <Currencies path="currencies" />

    <ReturnReasons path="return-reasons" />

    <Regions path="regions" />
    <RegionDetails path="regions/:id" />
    <NewRegion path="regions/new" />

    <Taxes path="taxes" />

    <Users path="team">
      <MemberTable path="/" />
      <RoleTable path="role" />
    </Users>

    <PersonalInformation path="personal-information" /> */}

    <PrivateRoute path="/" component={SettingsIndex} />
    <PrivateRoute path="details" component={Details} />
    <PrivateRoute path="currencies" component={Currencies} />
    <PrivateRoute path="return-reasons" component={ReturnReasons} />
    <PrivateRoute path="regions" component={Regions} />
    <PrivateRoute path="regions/:id" component={RegionDetails} />
    <PrivateRoute path="regions/new" component={NewRegion} />
    <PrivateRoute path="taxes" component={Taxes} />
    <PrivateRoute path="team" component={Users}>
      {/* <PrivateRoute path="/" component={MemberTable} />
      <PrivateRoute path="role" component={RoleTable} /> */}
    </PrivateRoute>
    <PrivateRoute path="personal-information" component={PersonalInformation} />
  </Router>
);

export default Settings;
