import { RouteComponentProps, useLocation } from "@reach/router"
import clsx from "clsx"
import { filter, isEmpty } from "lodash"
import { useAdminOrders } from "medusa-react"
import qs from "qs"
import React, { useEffect, useState } from "react"
import { usePagination, useTable } from "react-table"
import Spinner from "../../atoms/spinner"
import Table, { TablePagination } from "../../molecules/table"
import OrderFilters from "../order-filter-dropdown"
import useOrderTableColums from "./use-order-column"
import { useOrderFilters } from "./use-order-filters"
import FilterTable from "../../filterTable"
import RemarkModal from "../../../domain/orders/details/remark-modal"
import { useAdminRegions } from "medusa-react"

const DEFAULT_PAGE_SIZE = 15

const defaultQueryProps = {
  expand: "shipping_address,transaction",
  fields:
    "id,status,display_id,created_at,email,fulfillment_status,payment_status,total,currency_code,remark,exchange_rate,pay_currency",
}

const OrderTable: React.FC<RouteComponentProps> = () => {
  const location = useLocation()

  const {
    removeTab,
    setTab,
    saveTab,
    availableTabs: filterTabs,
    activeFilterTab,
    reset,
    paginate,
    setFilters,
    filters,
    setQuery: setFreeText,
    queryObject,
    representationObject,
  } = useOrderFilters(location.search, defaultQueryProps)
  const filtersOnLoad = queryObject

  const offs = parseInt(filtersOnLoad?.offset) || 0
  const lim = parseInt(filtersOnLoad.limit) || DEFAULT_PAGE_SIZE

  const [query, setQuery] = useState(filtersOnLoad?.query)
  const [numPages, setNumPages] = useState(0)

  const { orders, isLoading, count } = useAdminOrders(queryObject)

  useEffect(() => {
    const controlledPageCount = Math.ceil(count! / queryObject.limit)
    setNumPages(controlledPageCount)
  }, [orders])


  const [remarkModal, setRemarkModal] = useState<null | {
    remark: string
  }>(null)
  const [orderId, setOrderId] = useState<null | string>(null)

  const [columns] = useOrderTableColums(setRemarkModal, setOrderId)
  console.log('----offs / lim-----', offs, lim)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: orders || [],
      manualPagination: true,
      initialState: {
        pageSize: lim,
        pageIndex: offs / lim,
      },
      pageCount: numPages,
      autoResetPage: false,
    },
    usePagination
  )
  console.log('-----table------', pageCount, pageIndex)
  // 初始化筛选项值
  const [filterOpt, setFilterOpt] = useState([
    {
      label: '订单状态',
      vkey: 'orderState',
      type: 'select',
      defaultVal: 'all',
      options: [
        { label: '所有', value: 'all'},
        { label: '订单异常', value: '0' },
        { label: '已发货', value: '3' },
        { label: '待发货', value: '2' },
        { label: '待付款', value: '1' },
        { label: '订单取消', value: '4' },
      ]
    },
    {
      label: '地区',
      vkey: 'area',
      type: 'select',
      defaultVal: 'all',
      options: []
    },
    {
      label: '订单号',
      vkey: 'orderId',
      type: 'input'
    },
    {
      label: '顾客',
      vkey: 'customer',
      type: 'input'
    }
  ])
  const { regions, isLoading: isLoadingRegions } = useAdminRegions({
    offset: 0,
    limit: 999,
  })
  useEffect(() => { 
    if (regions) {
      const regArr = regions.map(item => ({ label: item.name, value: item.id }))
      filterOpt[1].options = [{ label: '所有', value: 'all'}, ...regArr]
    }
  }, [regions])
  
  const filterFulfillment = (state) => { 
    filters.fulfillment.open = false
    filters.fulfillment.filter = null
    filters.status.open = false
    filters.status.filter = null
    filters.payment.open = false
    filters.payment.filter = null
    switch (state) {
      case '0': // 订单异常
        filters.status.open = true
        filters.status.filter = ['abnormal']
        break
      case '1': // 待付款
        filters.payment.open = true
        filters.payment.filter = ['not_paid', 'awaiting']
        filters.status.open = true
        filters.status.filter = ['pending']
        break
      case '2': // 待发货
        filters.status.open = true
        filters.status.filter = ['pending']
        filters.fulfillment.open = true
        filters.fulfillment.filter = ['fulfilled', 'not_fulfilled']
        filters.payment.open = true
        filters.payment.filter = ['captured']
        break
      case '3': // 已发货
        filters.status.open = true
        filters.status.filter = ['pending']
        filters.payment.open = true
        filters.payment.filter = ['captured']
        filters.fulfillment.open = true
        filters.fulfillment.filter = ['shipped']
        break
      case '4': // 取消订单
        filters.status.open = true
        filters.status.filter = ['canceled']
        break
    }
  } 
  
  const changeFiltter = (filterRes) => {
    const isEmpty: any = []
    for (let key in filterRes) {
      if (filterRes[key] || filterRes[key] === 0) {
        isEmpty.push({ [key]: filterRes[key] })
      }
    }
    if (filterRes['orderState']) { 
      filterFulfillment(filterRes['orderState'])
    }
    if (filterRes['area']) { 
      if (filterRes['area'] === 'all') { 
        filters.region.open = false
        filters.region.filter = null
      } else { 
        filters.region.open = true
        filters.region.filter = [filterRes['area']]
      }
    }
    filters.query = filterRes['customer']
    filters.orderQuery = filterRes['orderId']
    if (isEmpty.length) {
      setFilters(filters)
    } else { 
      reset()
    }
  }
  // Debounced search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query) {
        setFreeText(query)
        gotoPage(0)
      } else {
        // if we delete query string, we reset the table view
        reset()
      }
    }, 400)

    return () => clearTimeout(delayDebounceFn)
  }, [query])

  const handleNext = () => {
    if (canNextPage) {
      paginate(1)
      nextPage()
    }
  }

  const handlePrev = () => {
    if (canPreviousPage) {
      paginate(-1)
      previousPage()
    }
  }

  const updateUrlFromFilter = (obj = {}) => {
    const stringified = qs.stringify(obj)
    window.history.replaceState(`/a/orders`, "", `${`?${stringified}`}`)
  }

  const refreshWithFilters = () => {
    const filterObj = representationObject

    if (isEmpty(filterObj)) {
      updateUrlFromFilter({ offset: 0, limit: DEFAULT_PAGE_SIZE })
    } else {
      updateUrlFromFilter(filterObj)
    }
  }

  const clearFilters = () => {
    reset()
    setQuery("")
  }

  const handleUpdateRemark = (data) => { 
    console.log(data)
  }

  useEffect(() => {
    refreshWithFilters()
  }, [representationObject])

  return (
    <div className="w-full overflow-y-auto flex flex-col justify-between min-h-[300px] h-full ">
      <FilterTable fopt={filterOpt} submitChange={changeFiltter}></FilterTable>
      <Table
        // filteringOptions={
        //   <OrderFilters
        //     filters={filters}
        //     submitFilters={setFilters}
        //     clearFilters={clearFilters}
        //     tabs={filterTabs}
        //     onTabClick={setTab}
        //     activeTab={activeFilterTab}
        //     onRemoveTab={removeTab}
        //     onSaveTab={saveTab}
        //   />
        // }
        // enableSearch
        // searchPlaceholder={ "搜索" }
        // handleSearch={setQuery}
        // searchValue={query}
        {...getTableProps()}
        className={clsx({ ["relative"]: isLoading })}
      >
        <Table.Head>
          {headerGroups?.map((headerGroup, index) => (
            <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((col, headerIndex) => (
                <Table.HeadCell {...col.getHeaderProps()}>
                  {col.render("Header")}
                </Table.HeadCell>
              ))}
            </Table.HeadRow>
          ))}
        </Table.Head>
        {isLoading || !orders ? (
          <div className="absolute flex items-center justify-center w-full h-full mt-10">
            <div className="">
              <Spinner size={"large"} variant={"secondary"} />
            </div>
          </div>
        ) : (
          <Table.Body {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <Table.Row
                  color={"inherit"}
                  linkTo={row.original.id}
                  {...row.getRowProps()}
                  className="group"
                >
                  {row.cells.map((cell, index) => {
                    return cell.render("Cell", { index })
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
        )}
      </Table>
      <TablePagination
        count={count!}
        limit={queryObject.limit}
        offset={queryObject.offset}
        pageSize={queryObject.offset + rows.length}
        title="Orders"
        currentPage={pageIndex + 1}
        pageCount={pageCount}
        nextPage={handleNext}
        prevPage={handlePrev}
        hasNext={canNextPage}
        hasPrev={canPreviousPage}
      />
      {remarkModal && (
        <RemarkModal
          handleClose={() => setRemarkModal(null)}
          handleSave={(obj) => handleUpdateRemark(obj)}
          remark={remarkModal.remark}
          id={ orderId }
        />
      )}
    </div>
  )
}

export default OrderTable
