import React, { useState, useEffect } from "react";
import { DatePicker, ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from "moment"
import 'moment/locale/zh-cn';
import { useQuery } from 'react-query'
import Medusa from '../../../../services/api'
import Button from "../../../../components/fundamentals/button"
import useNotification from "../../../../hooks/use-notification";
import { getErrorMessage } from "../../../../utils/error-messages";
const { RangePicker } = DatePicker;
moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD HH:mm:ss'
const TimeRange = () => { 
  const notification = useNotification();
  // 获取生效时间区间
  const getTimeRange = () => { 
    return Medusa['lotteryManage']['getRangeTime']()
  }

  // 设置生效时间区间
  const setTimeRange = () => { 
    Medusa['lotteryManage']['setRangeTime']({
      start_at: new Date(startDate as any).getTime(),
      over_at: new Date(endDate as any).getTime()
    }).then(res => { 
      if (res) { 
        notification("成功", "更新成功", "success");
        refetch()
        setEditState(false)
      }
    })
  }

  // 开启编辑取消编辑生效时间
  const [editState, setEditState] = useState(false)

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  
  // 获取生效时间
  const { data, refetch, isLoading } = useQuery('timeRange', getTimeRange)

  // 自定义时间选择器input部分
  const RangTimeInput = () => { 
    return (
      <div className="h-[32px] w-[250px] px-2 bg-transparent border rounded-rounded text-center leading-[31px]">
        {`${startDate ? moment(startDate).format(dateFormat) : '开始时间'} — ${endDate ? moment(endDate).format(dateFormat) : '结束时间'}`}
      </div>
    )
  }
  useEffect(() => { 
    const overAt = data?.data?.data?.over_at
    const startAt = data?.data?.data?.start_at
    if (overAt && startAt) { 
      setStartDate(moment(startAt).format(dateFormat) as any)
      setEndDate(moment(overAt).format(dateFormat) as any)
    }
  }, [data])
  return (
    <div className="mb-2">
      <div className="flex items-center">
        <span className="mr-2 text-[#333]">抽奖码生效时间：</span>
        {
          editState ? (
            <div className="flex items-center">
              {/* <ReactDatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={onChange}
                isClearable={true}
                customInput={ RangTimeInput() }
              /> */}

              <ConfigProvider locale={zhCN}>
                <RangePicker
                  defaultValue={[moment(startDate || new Date(), dateFormat), moment(endDate || new Date(), dateFormat)]}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                  }}
                  format={ dateFormat }
                  onChange={onChange}
                />
              </ConfigProvider>
              
              <Button
                className="w-[100px] ml-4"
                onClick={()=>setEditState(false)}
                size="small"
                variant="ghost"
              >
                取消
              </Button>
              <Button
                onClick={setTimeRange}
                className="w-[100px] ml-2"
                size="small"
                variant="primary"
              >
                保存
              </Button>
            </div>) : (
            <div className="flex items-center">
              {
                data?.data?.data?.over_at && data?.data?.data?.start_at ? (
                  <span>{moment(data?.data?.data?.start_at).format(dateFormat) + ' — ' + moment(data?.data?.data?.over_at).format(dateFormat)}</span>
                ) : (
                  <span className="text-gray-400">暂未设置</span>  
                )
              }
              <Button
                onClick={ () => setEditState(true) }
                className="w-[100px] ml-4"
                size="small"
                variant="primary"
              >
                编辑
              </Button>
            </div>    
          )
        }
      </div>
      <div className="mt-2 text-[12px] text-[#E42222]">备注：只有在该时间范围内的订单会生成抽奖码，请确保配置正确</div>
    </div>
  )
}

export default TimeRange