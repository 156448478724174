import React, { useEffect, useState, useReducer } from "react"
import useNotification from "../../hooks/use-notification"
import Medusa from "../../services/api"
import EditIcon from "../fundamentals/icons/edit-icon"
import RefreshIcon from "../fundamentals/icons/refresh-icon"
import TrashIcon from "../fundamentals/icons/trash-icon"
import StatusIndicator from "../fundamentals/status-indicator"
import SidebarTeamMember from "../molecules/sidebar-team-member"
import Table from "../molecules/table"
import DeletePrompt from "../organisms/delete-prompt"
import EditUser from "../organisms/edit-user-modal"

type UserListElement = {
  entity: any
  entityType: string
  tableElement: JSX.Element
}


const getInviteStatus = (invite) => {
  return new Date(invite.expires_at) < new Date() ? "expired" : "pending"
}

const fliterObj = {
  filterRole: null, // 记录选择的角色id
  filterState: '', // 记录选择的状态值
  filterSearch: '', // 记录搜索的值
}

const MemberTable: React.FC<{ userChangeNum: number;  setUserChangeNum: any}> = ({ userChangeNum, setUserChangeNum}) => {
  const [elements, setElements] = useState<UserListElement[]>([])
  const [shownElements, setShownElements] = useState<UserListElement[]>([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [deleteUser, setDeleteUser] = useState(false)
  const [selectedInvite, setSelectedInvite] = useState(null)
  // 团队权限 
  const [teamAuth, setTeamAuth] = useState([{
    title: '全部',
    count: elements.length,
    onClick: () => setShownElements(elements),
  }])

  const notification = useNotification()

  const [users, setUsers] = useState([])
  const [invites, setInvites] = useState([])
  const [shouldRefetch, setShouldRefetch] = useState(userChangeNum)

  const triggerRefetch = () => {
    // setShouldRefetch((prev) => prev + 1)
    setUserChangeNum()
  }

  useEffect(() => {
    setElements([
      ...users.map((user, i) => ({
        entity: user,
        entityType: "user",
        tableElement: getUserTableRow(user, i),
      })),
      ...invites.map((invite, i) => ({
        entity: invite,
        entityType: "invite",
        tableElement: getInviteTableRow(invite, i),
      })),
    ])
  }, [users, invites])

  const dealCount = (key) => { 
    return elements.filter(item => item.entity.roles.map(citem => citem.id).includes(key)).length
  }

  useEffect(() => {
    setShownElements(elements)
    Medusa['role']['getRoleList']({
      limit: 999,
      offset: 0
    }).then(res => { 
      const arr: any = [{
        title: '全部',
        count: elements.length,
        onClick: () => { 
          fliterObj.filterRole = null
          setResElement('filterRoleKey', null)
          // setShownElements(elements)
        },
      }]
      res.data.list.forEach(item => {
        arr.push({
          title: item.name,
          count: dealCount(item.id),
          onClick: () => { 
            fliterObj.filterRole = item.id
            setResElement('filterRoleKey', item.id)
            // setShownElements(elements.filter(e => e.entity.roles.map(citem => citem.id).includes(item.id)))
          },
        })
      });
      setTeamAuth(arr)
    })
  }, [elements])

  useEffect(() => {
    Medusa.users
      .list()
      .then((res) => res?.data)
      .then((userData) => {
        if (userData) { 
          Medusa.invites
          .list()
          .then((res) => res.data)
          .then((inviteData) => {
            setUsers(userData.users)
            setInvites(inviteData.invites)
          })
        }
      })
  }, [userChangeNum])

  const setfilterStateVal = (val) => { 
    fliterObj.filterState = val
    setResElement('filterStateKey', val)
  }

  const setResElement = (key, val) => { 
    let arr = elements
    if (fliterObj.filterRole) { 
      arr = arr.filter(e => e.entity.roles.map(citem => citem.id).includes(fliterObj.filterRole))
    }
    if (fliterObj.filterState) { 
      if (fliterObj.filterState === 'user') {
        arr = arr.filter((e) => e.entityType === fliterObj.filterState)
      } else { 
        arr = arr.filter((e) => e.entityType === "invite" && getInviteStatus(e.entity) === fliterObj.filterState)
      }
    }
    if (fliterObj.filterSearch) { 
      arr = arr.filter(
        (e) =>
          e.entity?.first_name?.includes(fliterObj.filterSearch) ||
          e.entity?.last_name?.includes(fliterObj.filterSearch) ||
          e.entity?.email?.includes(fliterObj.filterSearch) ||
          e.entity?.user_email?.includes(fliterObj.filterSearch)
      )
    }
    setShownElements(arr)
  }

  const handleClose = () => {
    setDeleteUser(false)
    setSelectedUser(null)
    setSelectedInvite(null)
  }

  const handleRole = (roles) => { 
    const roleArr = roles.map(item => item.name)
    return roleArr.join('、')
  }

  const getUserTableRow = (user, index) => {
    return (
      <Table.Row
        key={`user-${index}`}
        color={"inherit"}
        actions={[
          {
            label: "编辑用户",
            onClick: () => setSelectedUser(user),
            icon: <EditIcon size={20} />,
          },
          {
            label: "移除用户",
            variant: "danger",
            onClick: () => {
              setDeleteUser(true)
              setSelectedUser(user)
            },
            icon: <TrashIcon size={20} />,
          },
        ]}
      >
        <Table.Cell>
          <SidebarTeamMember user={user} />
        </Table.Cell>
        <Table.Cell className="w-80">{user.email}</Table.Cell>
        {/* <Table.Cell className="inter-small-semibold text-violet-60">
          {user.role.charAt(0).toUpperCase()}
          {user.role.slice(1)}
        </Table.Cell> */}
        <Table.Cell className="inter-small-semibold text-violet-60">
          { handleRole(user.roles)  }
        </Table.Cell>
        <Table.Cell><StatusIndicator title={"Active"} variant={"success"} /></Table.Cell>
      </Table.Row>
    )
  }

  const getInviteTableRow = (invite, index) => {
    return (
      <Table.Row
        key={`invite-${index}`}
        actions={[
          {
            label: "重新发送邀请",
            onClick: () => {
              Medusa.invites
                .resend(invite.id)
                .then(() => {
                  notification(
                    "成功",
                    "邀请链接已重新发送",
                    "success"
                  )
                })
                .then(() => triggerRefetch())
            },
            icon: <RefreshIcon size={20} />,
          },
          {
            label: "取消邀请",
            variant: "danger",
            onClick: () => {
              setSelectedInvite(invite)
            },
            icon: <TrashIcon size={20} />,
          },
        ]}
      >
        <Table.Cell className="text-grey-40">
          <SidebarTeamMember user={{ email: invite.user_email }} />
        </Table.Cell>
        <Table.Cell className="text-grey-40 w-80">
          {invite.user_email}
        </Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          {new Date(invite?.expires_at) < new Date() ? (
            <StatusIndicator title={"Expired"} variant={"danger"} />
          ) : (
            <StatusIndicator title={"Pending"} variant={"success"} />
          )}
        </Table.Cell>
      </Table.Row>
    )
  }

  const filteringOptions = [
    {
      title: "角色",
      options: teamAuth
      // options: [
      //   {
      //     title: "全部",
      //     count: elements.length,
      //     onClick: () => setShownElements(elements),
      //   },
      //   {
      //     title: "成员",
      //     count: elements.filter(
      //       (e) => e.entityType === "user" && e.entity.role === "member"
      //     ).length,
      //     onClick: () =>
      //       setShownElements(
      //         elements.filter(
      //           (e) => e.entityType === "user" && e.entity.role === "member"
      //         )
      //       ),
      //   },
      //   {
      //     title: "管理员",
      //     count: elements.filter(
      //       (e) => e.entityType === "user" && e.entity.role === "admin"
      //     ).length,
      //     onClick: () =>
      //       setShownElements(
      //         elements.filter(
      //           (e) => e.entityType === "user" && e.entity.role === "admin"
      //         )
      //       ),
      //   },
      //   {
      //     title: "没有团队权限",
      //     count: elements.filter((e) => e.entityType === "invite").length,
      //     onClick: () =>
      //       setShownElements(elements.filter((e) => e.entityType === "invite")),
      //   },
      // ],
    },
    {
      title: "状态",
      options: [
        {
          title: "全部",
          count: elements.length,
          onClick: () => { 
            // setShownElements(elements)
            setfilterStateVal('')
          },
        },
        {
          title: "Active",
          count: elements.filter((e) => e.entityType === "user").length,
          onClick: () =>
          // setShownElements(elements.filter((e) => e.entityType === "user")),
          { 
            setfilterStateVal('user')
          }
        },
        {
          title: "Pending",
          count: elements.filter(
            (e) =>
              e.entityType === "invite" &&
              getInviteStatus(e.entity) === "pending"
          ).length,
          onClick: () => { 
            // setShownElements(
            //   elements.filter(
            //     (e) =>
            //       e.entityType === "invite" &&
            //       getInviteStatus(e.entity) === "pending"
            //   )
            // )
            setfilterStateVal('pending')
          }
        },
        {
          title: "Expired",
          count: elements.filter(
            (e) =>
              e.entityType === "invite" &&
              getInviteStatus(e.entity) === "expired"
          ).length,
          onClick: () => { 
            setfilterStateVal('expired')
            // setShownElements(
            //   elements.filter(
            //     (e) =>
            //       e.entityType === "invite" &&
            //       getInviteStatus(e.entity) === "expired"
            //   )
            // ),
          }
          
        },
      ],
    },
  ]

  const handleUserSearch = (term: string) => {
    // setShownElements(
    //   elements.filter(
    //     (e) =>
    //       e.entity?.first_name?.includes(term) ||
    //       e.entity?.last_name?.includes(term) ||
    //       e.entity?.email?.includes(term) ||
    //       e.entity?.user_email?.includes(term)
    //   )
    // )
    fliterObj.filterState = term
    setResElement('filterSearchKey', term)
  }

  return (
    <div className="w-full h-full overflow-y-auto">
      
      <div className="w-full h-full">
        <Table
          filteringOptions={filteringOptions}
          enableSearch
          searchPlaceholder={ "搜索" }
          handleSearch={handleUserSearch}
        >
          <Table.Head>
            <Table.HeadRow>
              <Table.HeadCell className="w-72">姓名</Table.HeadCell>
              <Table.HeadCell className="w-80">电子邮件</Table.HeadCell>
              {/* <Table.HeadCell className="w-72">团队权限</Table.HeadCell> */}
              <Table.HeadCell className="w-72">角色</Table.HeadCell>
              <Table.HeadCell>状态</Table.HeadCell>
            </Table.HeadRow>
          </Table.Head>
          <Table.Body>{shownElements.map((e) => e.tableElement)}</Table.Body>
        </Table>
        <div className="mt-6 inter-small-regular text-grey-50">
          成员数量：{users.length}
        </div>
      </div>
      {selectedUser &&
        (deleteUser ? (
          <DeletePrompt
            text={"你确定移除当前用户?"}
            heading={"移除用户"}
            successText={ "用户被移除" }
            onDelete={() =>
              Medusa.users.delete(selectedUser.id).then(() => {
                // notification("成功", "用户被移除", "success")
                triggerRefetch()
              })
            }
            handleClose={handleClose}
          />
        ) : (
          <EditUser
            handleClose={handleClose}
            user={selectedUser}
            onSubmit={() => {
              notification("成功", "用户信息更新", "success")
              triggerRefetch()
            }}
          />
        ))}
      {selectedInvite && (
        <DeletePrompt
          text={"你确定取消对当前用户的邀请?"}
          heading={"移除邀请"}
          successText={ "邀请被取消" }
          onDelete={() =>
            Medusa.invites.delete(selectedInvite.id).then(() => {
              // notification("成功", "邀请被取消", "success")
              triggerRefetch()
            })
          }
          handleClose={handleClose}
        />
      )}
    </div>
  )
}

export default MemberTable
