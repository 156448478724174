import React from "react"

import StatusDot from "../../../../components/fundamentals/status-indicator"

// 直接用status来判断 订单的状态
export const OrderStatusComponent = ({ status }) => {
  switch (status) {
    case "completed":
      return <StatusDot title="Completed" variant="success" />
    case "pending":
      return <StatusDot title="Processing" variant="default" />
    case "canceled":
      return <StatusDot title="Canceled" variant="danger" />
    case "requires_action":
      return <StatusDot title="Requires action" variant="danger" />
    default:
      return null
  }
}

// 根据 支付状态 和 配送状态来判断订单的状态
export const OrderStatusTemplate = ({ fulfillmentStatus, paymentStatus, orderStatus }) => { 
  // 订单状态 待付款，待发货，已发货，订单异常
  if (orderStatus === 'abnormal') {
    return <span>订单异常</span>
  }else if (orderStatus === 'canceled') {
    return <span>订单取消</span>
  } else if (orderStatus === 'completed') {
    return <span>已完成</span>
  } else { 
    if (['not_paid', 'awaiting'].includes(paymentStatus)) {
      return <span>待支付</span>
    } else if (paymentStatus === 'captured') {
      if (['fulfilled', 'not_fulfilled'].includes(fulfillmentStatus)) {
        return <span>待发货</span>
      } else if (fulfillmentStatus === 'shipped') {
        return <span>已发货</span>
      } else {
        return <span>发货状态{fulfillmentStatus}</span>
      }
    } else { 
      return <span>支付状态{paymentStatus}</span>
    }
  }
}