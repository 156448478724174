import { Router, useLocation, redirectTo } from "@reach/router";
import { navigate } from "gatsby";
import React, { useContext, useState, useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import PrivateRoute from "../components/private-route";
import SEO from "../components/seo";
import Layout from "../components/templates/layout";
import Collections from "../domain/collections";
import Customers from "../domain/customers";
import Discounts from "../domain/discounts";
import GiftCards from "../domain/gift-cards";
import Oauth from "../domain/oauth";
import Orders from "../domain/orders";
import DraftOrders from "../domain/orders/draft-orders";
import Pricing from "../domain/pricing";
import Products from "../domain/products";
import Settings from "../domain/settings";
import AdminManage from "../domain/admin-manage";
import LotteryManage from "../domain/lottery-manage/bohetang";
import HomePage from "../domain/home";
import { AccountContext } from "../context/account";
import { fromJSON } from "postcss";

const IndexPage = () => {
  useHotkeys("g + o", () => navigate("/a/orders"));
  useHotkeys("g + p", () => navigate("/a/products"));
  const [authLs, setAuthLs] = useState([]);
  const account = useContext(AccountContext);
  const location = useLocation();
  const routerArr = [
    {
      path: "oauth/:app_name",
      component: Oauth,
    },
    {
      path: "products/*",
      component: Products,
      id: "3",
    },
    {
      path: "collections/*",
      component: Collections,
    },
    {
      path: "gift-cards/*",
      component: GiftCards,
    },
    {
      path: "orders/*",
      component: Orders,
      id: "1",
    },
    {
      path: "draft-orders/*",
      component: DraftOrders,
    },
    {
      path: "discounts/*",
      component: Discounts,
    },
    {
      path: "customers/*",
      component: Customers,
      id: "4",
    },
    {
      path: "pricing/*",
      component: Pricing,
    },
    {
      path: "settings/*",
      component: Settings,
      id: "6",
    },
    {
      path: "admin-manage/*",
      component: AdminManage,
      id: "5",
    },
    {
      path: "lottery-manage/bohetang",
      component: LotteryManage,
      id: "2",
    },
  ];
  useEffect(() => {
    Promise.all([account.session(), account.meunAuth()])
      .then((res) => {
        let arr = [];
        res[1].roles.forEach((item) => {
          arr = Array.from(new Set([...arr, ...item.permissions]));
        });
        setAuthLs(arr);
      })
      .catch(() => {
        navigate("/login");
      });
  }, []);
  return (
    <Layout>
      <SEO title="Iconme" />
      <Router basepath="a" className="h-full">
        {/* <PrivateRoute path="oauth/:app_name" component={Oauth} />
        <PrivateRoute path="products/*" component={Products} />
        <PrivateRoute path="collections/*" component={Collections} />
        <PrivateRoute path="gift-cards/*" component={GiftCards} />
        <PrivateRoute path="orders/*" component={Orders} />
        <PrivateRoute path="draft-orders/*" component={DraftOrders} />
        <PrivateRoute path="discounts/*" component={Discounts} />
        <PrivateRoute path="customers/*" component={Customers} />
        <PrivateRoute path="pricing/*" component={Pricing} />
        <PrivateRoute path="settings/*" component={Settings} />
        <PrivateRoute path="admin-manage/*" component={AdminManage} />
        <PrivateRoute
          path="lottery-manage/bohetang"
          component={LotteryManage}
        /> */}
        <PrivateRoute path="home/*" component={HomePage} />
        {/* 动态加载 */}
        {routerArr.map((item) => {
          if (authLs.includes(item.id)) {
            return (
              <PrivateRoute
                path={item.path}
                component={item.component}
                key={item.id}
              />
            );
          }
        })}
      </Router>
    </Layout>
  );
};

export default IndexPage;
