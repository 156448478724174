import { RouteComponentProps } from "@reach/router"
import { useAdminCustomer } from "medusa-react"
import moment from "moment"
import React, { useState, useContext, useEffect } from "react"
import Avatar from "../../../components/atoms/avatar"
import Spinner from "../../../components/atoms/spinner"
import EditIcon from "../../../components/fundamentals/icons/edit-icon"
import TrashIcon from "../../../components/fundamentals/icons/trash-icon"
import StatusDot from "../../../components/fundamentals/status-indicator"
import Actionables from "../../../components/molecules/actionables"
import Breadcrumb from "../../../components/molecules/breadcrumb"
import BodyCard from "../../../components/organisms/body-card"
import RawJSON from "../../../components/organisms/raw-json"
import CopyToClipboard from "../../../components/atoms/copy-to-clipboard"
import CustomerOrdersTable from "../../../components/templates/customer-orders-table"
import EditCustomerModal from "./editRemark"
import { EditOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import { AccountContext } from "../../../context/account";

type CustomerDetailProps = {
  id: string
} & RouteComponentProps

const CustomerDetail: React.FC<CustomerDetailProps> = ({ id }) => {
  const { customer, isLoading } = useAdminCustomer(id, {})
  const [showEdit, setShowEdit] = useState(false)
  const [orderShow, setOrderShow] = useState(false)
  const account = useContext(AccountContext);

  const customerName = () => {
    if (customer?.first_name && customer?.last_name) {
      return `${customer.first_name} ${customer.last_name}`
    } else {
      return customer?.email
    }
  }
  useEffect(() => { 
    if (account.roles.length) { 
      let arr:any[] = [];
      account.roles.forEach((item) => {
        arr = Array.from(new Set([...arr, ...item.permissions]));
      });
      if (arr.includes('1')) {
        setOrderShow(true)
      }
    }
  }, [])
  const actions = [
    {
      label: "Edit",
      onClick: () => setShowEdit(true),
      icon: <EditIcon size={20} />,
    },
    {
      label: "Delete (not implemented yet)",
      onClick: () => console.log("TODO: delete customer"),
      variant: "danger",
      icon: <TrashIcon size={20} />,
    },
  ]
  
  const addressKey = ['first_name', 'email', 'phone', 'country_code', 'province', 'postal_code', 'address_1', 'company', 'address_2']
  const addressList = (record) => { 
    const addressLsContent = record.map((item, index) => { 
      return (<Row key={index} gutter={[8,16]} className="mt-3">
        {
          addressKey.map((key, cindex) => { 
            return (
              <Col
                className="gutter-row"
                key={`${cindex}`}
                span={ ['email', 'phone', 'province', 'address_1', 'address_2'].includes(key) ? 3 : 2
              }>
                {key === 'province' ? (
                  <div className="break-all text-[#333]">{`${item[key]}、${item['city']}`}</div>
                  ) :key === 'first_name' ?(<div className="break-all text-[#333]">{item['first_name'] + ' ' + item['last_name']}</div>) : (
                    <div className="break-all text-[#333]">{item[key] || '--'}</div>
                  )
                }
              </Col>
            )
          })
        }
      </Row>)
    })
    return addressLsContent
  }

  return (
    <div>
      <Breadcrumb
        currentPage={"顾客详情"}
        previousBreadcrumb={"顾客"}
        previousRoute="/a/customers"
      />
      <BodyCard className={"h-auto w-full pt-[0px] mb-4 min-h-[138px]"}>
        {/* <div className="h-[120px] w-full absolute top-0 right-0 left-0 bg-gradient-to-b from-fuschia-20 z-0" /> */}
        <div className="flex flex-col overflow-y-auto grow">
          {/* <div className="w-[64px] h-[64px] mb-4">
            <Avatar
              user={customer}
              font="inter-2xlarge-semibold"
              color="bg-fuschia-40"
            />
          </div> */}
          <div className="flex items-center justify-between">
            <div className="flex">
              <div className="flex truncate items-align inter-xlarge-semibold text-grey-90">
                {/* {customerName()} */}
                <div className="flex items-center mr-4">
                  <span className="text-[15px] text-[#333] font-semibold mr-2">钱包: {customer?.wallet_address || '--' }</span>
                  {customer?.wallet_address && (
                    <CopyToClipboard
                      value={customer?.wallet_address}
                      showValue={false}
                      iconSize={14}
                    />
                  )}
                </div>
                <div className="flex items-center">
                  <span className="text-[15px] text-[#333] font-semibold mr-2">推特: {customer?.twitter?'@'+customer?.twitter: '--' }</span>
                  {customer?.twitter && (
                    <CopyToClipboard
                      value={customer?.twitter}
                      showValue={false}
                      iconSize={14}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* <Actionables actions={actions} /> */}
          </div>
          {/* <h3 className="inter-small-regular pt-1.5 text-grey-50">
            {customer?.email}
          </h3> */}
          <div className="inter-small-regular pt-1.5 text-grey-50">
            <span className="mr-5">{customer?.created_at ? moment(customer?.created_at).format('YYYY年MM月DD日 HH:mm') : '--'}</span>
            <span className="mr-2">备注: {customer?.remark || '--'}</span>
            <EditOutlined size={14} onClick={() => setShowEdit(true)} />
          </div>
        </div>
        <div className="mt-6 text-grey-50">收货地址</div>
        <Row className="mt-2" gutter={[8,18]}>
          <Col className="gutter-row" span={2}>
            <div className="text-[#333]">姓名</div>
          </Col>
          <Col className="gutter-row" span={3}>
            <div className="text-[#333]">电子邮箱</div>
          </Col>
          <Col className="gutter-row" span={3}>
            <div className="text-[#333]">电话</div>
          </Col>
          <Col className="gutter-row" span={2}>
            <div className="text-[#333]">国家</div>
          </Col>
          <Col className="gutter-row" span={3}>
            <div className="text-[#333]">州/省、城市</div>
          </Col>
          <Col className="gutter-row" span={2}>
            <div className="text-[#333]">邮政编码</div>
          </Col>
          <Col className="gutter-row" span={3}>
            <div className="text-[#333]">地址</div>
          </Col>
          <Col className="gutter-row" span={3}>
            <div className="text-[#333]">公司</div>
          </Col>
          <Col className="gutter-row" span={3}>
            <div className="text-[#333]">公寓</div>
          </Col>
        </Row>
        {customer?.shipping_addresses?.length ? (addressList(customer.shipping_addresses)):(<div className="text-[#9e9e9e] text-center pt-6">暂无数据</div>)}
        
        {/* <div className="flex mt-2 space-x-6 divide-x">
          <div className="flex flex-col">
            <div className="mb-1 inter-smaller-regular text-grey-50">
              First seen
            </div>
            <div>{moment(customer?.created_at).format("DD MMM YYYY")}</div>
          </div>
          <div className="flex flex-col pl-6">
            <div className="mb-1 inter-smaller-regular text-grey-50">Phone</div>
            <div className="truncate max-w-[200px]">
              {customer?.phone || "N/A"}
            </div>
          </div>
          <div className="flex flex-col pl-6">
            <div className="mb-1 inter-smaller-regular text-grey-50">
              Orders
            </div>
            <div>{customer?.orders.length}</div>
          </div>
          <div className="flex flex-col pl-6 h-100">
            <div className="mb-1 inter-smaller-regular text-grey-50">User</div>
            <div className="flex items-center justify-center h-50">
              <StatusDot
                variant={customer?.has_account ? "success" : "danger"}
                title={customer?.has_account ? "True" : "False"}
              />
            </div>
          </div>
        </div> */}

      </BodyCard>
      {
        orderShow && (
          <BodyCard
            title={`订单 (${customer?.orders.length})`}
            subtitle=""
          >
            {isLoading || !customer ? (
              <div className="flex items-center justify-center w-full pt-2xlarge">
                <Spinner size={"large"} variant={"secondary"} />
              </div>
            ) : (
              <div className="flex flex-col pt-2 grow mt-large">
                <CustomerOrdersTable customerId={customer.id} />
              </div>
            )}
          </BodyCard>
        )
      }
      {/* <div className="mt-large">
        <RawJSON data={customer} title="Raw customer" />
      </div> */}

      {showEdit && (
        <EditCustomerModal
          customer={customer}
          handleClose={() => setShowEdit(false)}
        />
      )}
    </div>
  )
}

export default CustomerDetail
