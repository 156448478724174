import { RouteComponentProps } from "@reach/router"
import { useAdminOrders } from "medusa-react"
import moment from "moment"
import React, { useRef, useState } from "react"
import { useObserveWidth } from "../../../hooks/use-observe-width"
import { stringDisplayPrice, priceAmount } from "../../../utils/prices"
import StatusDot from "../../fundamentals/status-indicator"
import Table, { TablePagination } from "../../molecules/table"
import { OrderStatusTemplate } from '../../../domain/orders/details/templates'

type CustomerOrdersTableProps = {
  customerId: string
} & RouteComponentProps

const CustomerOrdersTable: React.FC<CustomerOrdersTableProps> = ({
  customerId,
}) => {
  // TODO: Use react-table
  // I've hard coded the limit to 14 for now, since it's quite rare
  // to have customers putting in that many orders. This is only until
  // we've successfully implemented react-table, that will allow us
  // to add pagination
  const limit = 10
  const [offset, setOffset] = useState(0)
  const { orders, isLoading, count } = useAdminOrders({
    customer_id: customerId,
    offset: offset*limit,
    limit: limit,
    expand: 'items,transaction'
  })

  const handleNext = () => { 
    if (offset < Math.floor((count as number) / limit)) { 
      setOffset(offset + 1)
    }
  }

  const handlePrev = () => { 
    if (offset > 0) { 
      setOffset(offset - 1)
    }
  }

  const canNextPage = () => { 
    return offset * limit < Math.floor((count as number)/limit)
  }

  const containerRef = useRef(null)
  const width = useObserveWidth(containerRef)

  const calcImages = (order) => {
    const columns = Math.max(Math.floor(width / 20) - 1, 1)
    const visibleImages = order.items?.slice(0, columns)
    const remainder = order.items.length - columns

    return { visibleImages, remainder }
  }

  const decideStatus = (order) => {
    switch (order.payment_status) {
      case "captured":
        return <StatusDot variant="success" title={"Paid"} />
      case "awaiting":
        return <StatusDot variant="warning" title={"Awaiting"} />
      case "requires":
        return <StatusDot variant="danger" title={"Requires action"} />
      default:
        return <StatusDot variant="primary" title={"N/A"} />
    }
  }

  const dealOrderStatus = (order) => { 
    // 订单状态 待付款，待发货，已发货，订单异常；
    if (['not_paid', 'awaiting'].includes(order.payment_status)) {
      return '待支付'
    } else if (order.payment_status === 'captured') {
      if (['fulfilled', 'not_fulfilled'].includes(order.fulfillment_status)) {
        return '待发货'
      } else if (order.fulfillment_status === 'shipped') {
        return '已发货'
      } else {
        return `发货状态${order.fulfillment_status}`
      }
    } else { 
      return `支付状态${order.payment_status}`
    }
  }

  return (
    <div className="w-full h-full overflow-y-auto">
      <Table>
        <Table.Head>
          <Table.HeadRow>
            <Table.HeadCell className="w-[75px]">订单号</Table.HeadCell>
            <Table.HeadCell>商品</Table.HeadCell>
            <Table.HeadCell>日期</Table.HeadCell>
            <Table.HeadCell>订单状态</Table.HeadCell>
            <Table.HeadCell>订单金额</Table.HeadCell>
            <Table.HeadCell>付款金额</Table.HeadCell>
          </Table.HeadRow>
        </Table.Head>
        <Table.Body>
          {orders?.map((order, index) => {
            const { remainder, visibleImages } = calcImages(order)

            return (
              <Table.Row
                key={`invite-${index}`}
                linkTo={`/a/orders/${order.id}`}
                className="py-2"
              >
                <Table.Cell className="pr-3 text-grey-90">
                  #{order.id}
                </Table.Cell>
                <Table.Cell className="flex w-30">
                  <div
                    ref={containerRef}
                    className="flex space-x-1 w-[60px] mr-2 items-center"
                  >
                    {visibleImages.map((tag) => (
                      <div className="h-[35px] w-[25px] flex items-center ">
                        <img
                          className="object-cover rounded"
                          src={tag.variant?.image_url || tag.thumbnail}
                        />
                      </div>
                    ))}
                  </div>
                  {remainder > 0 && (
                    <div className="flex items-center text-grey-40 inter-small-regular">
                      + {remainder} more
                    </div>
                  )}
                </Table.Cell>
                <Table.Cell className="">
                  {moment(order.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </Table.Cell>
                <Table.Cell className="">{OrderStatusTemplate({
                  fulfillmentStatus: order.fulfillment_status,
                  paymentStatus: order.payment_status,
                  orderStatus: order.status,
                })}</Table.Cell>
                <Table.Cell className="truncate">
                  {stringDisplayPrice({
                    amount: order.total,
                    currencyCode: order.currency_code,
                  })}
                </Table.Cell>
                <Table.Cell className="">
                  {priceAmount(order.transaction?.value, order.transaction?.token_symbol, order.transaction?.token_decimal )}
                </Table.Cell>
                <Table.Cell />
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <TablePagination
        count={count!}
        limit={limit}
        offset={offset*limit}
        pageSize={offset*limit + (orders?orders.length:0)}
        title="订单"
        currentPage={offset + 1}
        pageCount={Math.ceil((count?count:0)/limit)}
        nextPage={handleNext}
        prevPage={handlePrev}
        hasNext={offset < Math.floor((count?count:0)/limit)}
        hasPrev={offset > 0}
      />
    </div>
  )
}

export default CustomerOrdersTable
