import { LineItemTaxLine, MoneyAmount, Order } from "@medusajs/medusa";
import Decimal from "decimal.js";
import { currencies } from "./currencies";

export function normalizeAmount(currency: string, amount: number): number {
  const divisor = getDecimalDigits(currency);
  return Math.floor(amount) / divisor;
}

export function displayAmount(currency: string, amount: number) {
  const normalizedAmount = normalizeAmount(currency, amount);
  return normalizedAmount.toFixed(
    currencies[currency.toUpperCase()].decimal_digits
  );
}

export const extractUnitPrice = (item, region, withTax = true) => {
  let itemPrice = item.unit_price;

  if (itemPrice === undefined) {
    const regionPrice = item.prices.find(
      (p) => p.currency_code === region.currency_code
    );

    itemPrice = regionPrice.amount;
  }

  if (itemPrice) {
    if (withTax) {
      return itemPrice * (1 + region.tax_rate / 100);
    } else {
      return itemPrice;
    }
  }

  return 0;
};

export const displayUnitPrice = (item, region) => {
  const currCode = region.currency_code.toUpperCase();

  const price = extractUnitPrice(item, region);
  return `${displayAmount(currCode, price)} ${currCode}`;
};

export const extractOptionPrice = (price, region) => {
  let amount = price;
  amount = (amount * (1 + region.tax_rate / 100)) / 100;
  return `${amount} ${region.currency_code.toUpperCase()}`;
};

/**
 * Checks the list of currencies and returns the divider/multiplier
 * that should be used to calculate the persited and display amount.
 * @param currency
 * @return {number}
 */
export function getDecimalDigits(currency: string) {
  const divisionDigits = currencies[currency.toUpperCase()].decimal_digits;
  return Math.pow(10, divisionDigits);
}

export function persistedPrice(currency: string, amount: number): number {
  const multiplier = getDecimalDigits(currency);
  return amount * multiplier;
}

export const stringDisplayPrice = ({ amount, currencyCode }) => {
  if (!amount || !currencyCode) {
    return `N/A`;
  }

  const display = displayAmount(currencyCode, amount);
  return `${display} ${currencyCode.toUpperCase()}`;
};

export const getNativeSymbol = (currencyCode: string) => {
  return currencies[currencyCode.toUpperCase()].symbol_native;
};

type FormatMoneyProps = {
  amount: number;
  currency: string;
  digits?: number;
  tax?: number | LineItemTaxLine[];
};

export function formatAmountWithSymbol({
  amount,
  currency,
  digits,
  tax = 0,
}: FormatMoneyProps) {
  let locale = "en-US";

  // We need this to display 'Kr' instead of 'DKK'
  if (currency.toLowerCase() === "dkk") {
    locale = "da-DK";
  }

  digits = digits ?? currencies[currency.toUpperCase()].decimal_digits;

  const normalizedAmount = normalizeAmount(currency, amount);

  const taxRate =
    tax instanceof Array ? tax.reduce((acc, curr) => acc + curr.rate, 0) : tax;
  // formatVal = normalizedAmount * (1 + taxRate / 100)
  const formatVal = new Decimal(normalizedAmount).mul(
    new Decimal(1 + taxRate / 100)
  );
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: digits,
  }).format(normalizedAmount);
}

export const extractNormalizedAmount = (
  amounts: Omit<MoneyAmount, "beforeInsert">[],
  order: Omit<Order, "beforeInsert">
) => {
  let amount = amounts.find((ma) => ma.region_id === order.region_id);

  if (!amount) {
    amount = amounts.find((ma) => ma.currency_code === order.currency_code);
  }

  if (amount) {
    return normalizeAmount(order.currency_code, amount.amount);
  }

  return 0;
};

export const priceAmount = (val, unit, rate?) => {
  if (unit) {
    val = val ? Number(val) : 0;
    rate = rate ? Number(rate) : 18;
    const num = new Decimal(val).dividedBy(new Decimal(Math.pow(10, rate)));
    var str = "" + num;
    if (!/e/i.test(str)) {
      return num + " " + unit.toUpperCase();
    }
    return num.toFixed(18).replace(/\.?0+$/, "") + " " + unit.toUpperCase();
  } else {
    return "--";
  }
};

export const price = (val, rate?) => {
  val = val ? Number(val) : 0;
  rate = rate ? Number(rate) : 18;
  const num = new Decimal(val).dividedBy(new Decimal(Math.pow(10, rate)));
  var str = "" + num;
  if (!/e/i.test(str)) {
    return num.toFixed(2);
  }
  return num.toFixed(2).replace(/\.?0+$/, "");
};

export const inputPrice = (val) => {
  console.log("---------", val);
  val = val.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
  val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
  val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  val = val.replace(/^(\-)*(\d+)\.(\d{18}).*$/, "$1$2.$3"); //只能输入18个小数
  if (val.indexOf(".") < 0 && val != "") {
    //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    val = parseFloat(val);
  }
  // 解决首位直接输入 '小数点'问题
  if (val.length == 1 && val.charAt(0) == ".") {
    val = "";
  } else if (val.length > 1 && val.charAt(0) == ".") {
    val = val.substring(1, val.indexOf(".") + 3);
  } else if (val.length > 1 && val.charAt(0) == "." && val.charAt(1) == "0") {
    val = val.substring(val.indexOf("0") + 1, val.length);
  }
  val = val.toString();
  if (val.toString().length) {
    const strArr = val.split(".");
    if (strArr[0].length > 9) {
      strArr[0] = strArr[0].substring(0, 9);
    }
    if (strArr.length > 1) {
      val = strArr[0] + "." + strArr[1];
    } else {
      val = strArr[0];
    }
  }
  return val;
};

export const countPriceRate = (val) => {
  val = val ? Number(val) : 1;
  const num = new Decimal(1).dividedBy(new Decimal(val));
  var str = "" + num;
  if (!/e/i.test(str)) {
    return num.toFixed(8);
  }
  return num.toFixed(8).replace(/\.?0+$/, "");
};
