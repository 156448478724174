export const narrowTxt = (val) => {
  if (typeof val !== "string") {
    return val;
  }
  if (val.length > 14) {
    const prestr = val.slice(0, 6);
    const laststr = val.substring(val.length - 4);
    return `${prestr}...${laststr}`;
  } else {
    return val;
  }
};
