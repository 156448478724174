import React, { useContext } from "react"
import PageDescription from "../atoms/page-description"
import { LayeredModalContext } from "../molecules/modal/layered-modal";

const SettingsOverview: React.FC = ({ children }) => {
  const { t } = useContext(LayeredModalContext);
  return (
    <div>
      <PageDescription
        title={t("settings.title")}
        subtitle={""}
      />
      <div className="grid grid-cols-1 medium:grid-cols-2 auto-cols-fr gap-x-base gap-y-xsmall">
        {children}
      </div>
    </div>
  )
}

export default SettingsOverview
