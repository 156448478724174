import React from "react"

type EventItemContainerProps = {
  item: {
    thumbnail?: string
    title: string
    quantity: number
    variant: {
      title: string
    }
  }
}

const EventItemContainer: React.FC<EventItemContainerProps> = ({ item }) => {
  if (!item) {
    return null
  }
  return (
    <div className="flex items-center gap-x-small mb-base last:mb-0">
      {item.thumbnail && (
        <div className="h-10 w-[40px] min-w-[40px] rounded-base overflow-hidden">
          <img
            src={item.variant?.image_url || item.thumbnail}
            alt={`Thumbnail for ${item.title}`}
            className="object-cover w-full h-full"
          />
        </div>
      )}
      <div className="flex flex-col w-full inter-small-regular">
        <div className="flex items-center justify-between w-full">
          <p>{item.title}</p>
          <span className="inter-small-semibold text-violet-60">{`x${item.quantity}`}</span>
        </div>
        <div>
        {item?.options && (
          Object.values(item?.options).map(item=>(
            <span className="inter-small-regular text-grey-50 inline-block py-[2px] px-[5px] bg-[#eee] mr-1">
              {item}
            </span>
          ))
        )}
        </div>
        <p className="text-grey-50">{item.metadata?.nft_module?.tokenName || '--'}</p>
      </div>
    </div>
  )
}

export default EventItemContainer
