import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as SelectPrimitive from '@radix-ui/react-select';
import Button from "../fundamentals/button"
import Select from "./Select"

const FilterTable = ({ fopt, submitChange }) => { 
  const { control, handleSubmit, register, setValue, reset } = useForm()
  const handleSearch = (val) => {
    submitChange(val)
  }
  const resetFrom = () => {
    let opt = {}
    fopt.forEach(item => {
      if (item.defaultVal) {
        opt[item.vkey] = item.defaultVal
      } else { 
        opt[item.vkey] = ''
      }
    });
    reset(opt)
    submitChange(opt)
  }
  return (
    <div className="flex justify-between items-start py-[10px] w-full">
      <div className="flex flex-wrap gap-x-base">
        {fopt.map(item => { 
          if (item.type === 'input') {
            return (
              <div className="flex items-center mb-2" key={item.vkey}>
                <span className="mr-2 text-[#333]">{item.label}</span>
                <input className="h-[32px] px-2 py-4 bg-transparent border outline-none outline-0 leading-base rounded-rounded inter-small-regular placeholder:text-grey-40 focus-within:shadow-input focus-within:border focus-within:border-violet-60" type="text" name={item.vkey} placeholder="请输入" ref={register} />
              </div>
            )
          } else if(item.type === 'select') { 
            return (
              <Controller
                control={control}
                name={ item.vkey }
                ref={register()}
                key={ item.vkey }
                render={ 
                  ({ value }) => (
                    <div className="flex items-center mb-2">
                      <span className="mr-2 text-[#333]">{ item.label }</span>
                      <Select val={value} dval={ item.defaultVal ||'' } options={item.options} changeValue={ (val) => setValue(item.vkey, val) }></Select>
                    </div>
                  )
                }
              ></Controller>
            )
          }
        })}
      </div>
      <div className="flex gap-x-base">
        <Button
          className="w-[60px]"
          onClick={resetFrom}
          size="small"
          variant="ghost"
        >
          重置
        </Button>
        <Button
          onClick={handleSubmit(handleSearch)}
          type="submit"
          className="w-[60px]"
          size="small"
          variant="primary"
        >
          搜索
        </Button>
      </div>
    </div>
  )
}

export default FilterTable