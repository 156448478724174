import { useAdminUpdateCustomer } from "medusa-react"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form";
import Modal from "../../../components/molecules/modal";
import Textarea from "../../../components/molecules/textarea"
import Button from "../../../components/fundamentals/button";
import useNotification from "../../../hooks/use-notification";
import { getErrorMessage } from "../../../utils/error-messages";

const editRemarkModal = ({ handleClose, customer }) => { 
  const { register, reset, handleSubmit } = useForm();

  const notification = useNotification();

  const updateCustomer = useAdminUpdateCustomer(customer.id);

  const submit = (data) => {
    updateCustomer.mutate(data, {
      onSuccess: () => {
        handleClose();
        notification("成功", "更新客户信息成功", "success");
      },
      onError: (err) => {
        handleClose();
        notification("失败", getErrorMessage(err), "error");
      },
    });
  };

  useEffect(() => {
    reset({
      remark: customer.remark || "",
    });
  }, []);
  
  return (
    <Modal handleClose={handleClose}>
      <Modal.Body>
        <Modal.Header handleClose={handleClose}>
          <span className="inter-xlarge-semibold">修改备注</span>
        </Modal.Header>
        <Modal.Content>
          <div className="flex w-full">
            <Textarea
              name="remark"
              id="remark"
              label="备注"
              placeholder="请输入备注"
              className="row-span-full"
              rows={4}
              ref={register}
            />
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex justify-end w-full">
            <Button
              variant="ghost"
              size="small"
              onClick={handleClose}
              className="mr-2"
            >
              取消
            </Button>
            <Button
              loading={updateCustomer.isLoading}
              variant="primary"
              className="min-w-[100px]"
              size="small"
              onClick={handleSubmit(submit)}
            >
              保存
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default editRemarkModal