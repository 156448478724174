import {
  useAdminCreateVariant,
  useAdminDeleteVariant,
  useAdminUpdateVariant,
} from "medusa-react"
import React, { useState } from "react"
import VariantEditor from "../../domain/products/details/variants/variant-editor"
import { buildOptionsMap } from "../../domain/products/product-form/utils"
import useImperativeDialog from "../../hooks/use-imperative-dialog"
import useNotification from "../../hooks/use-notification"
import { getErrorMessage } from "../../utils/error-messages"
import DuplicateIcon from "../fundamentals/icons/duplicate-icon"
import EditIcon from "../fundamentals/icons/edit-icon"
import TrashIcon from "../fundamentals/icons/trash-icon"
import GridInput from "../molecules/grid-input"
import Table from "../molecules/table"
import FileUploadField from "../../components/atoms/file-upload-field"
import IconTooltip from "../../components/molecules/icon-tooltip"
import { useGridColumns } from "./use-grid-columns"
import { Col, Row, Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import morentupian from "../../assets/images/morentupian.png"
import Medusa from "../../services/api"

const VariantGrid = ({ product, variants, edit, onVariantsChange }) => {
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState<{
    prices: any[]
    origin_country: string
    options: any[]
    [k: string]: any
  } | null>(null)

  const createVariant = useAdminCreateVariant(product?.id)
  const updateVariant = useAdminUpdateVariant(product?.id)
  const deleteVariant = useAdminDeleteVariant(product?.id)

  const notification = useNotification()
  const dialog = useImperativeDialog()

  const columns = useGridColumns(product, edit)

  const handleChange = (index, field, value) => {
    const newVariants = [...variants]
    newVariants[index] = {
      ...newVariants[index],
      [field]: value,
    }
    onVariantsChange(newVariants)
  }

  const getDisplayValue = (variant, column) => {
    const { formatter, field } = column
    return formatter ? formatter(variant[field]) : variant[field]
  }

  const handleUpdateVariant = (data) => {
    updateVariant.mutate(
      { variant_id: selectedVariant?.id, ...data },
      {
        onSuccess: () => {
          notification("成功", "更新规格成功", "success")
          setSelectedVariant(null)
          setIsDuplicate(false)
        },
        onError: (err) => {
          notification("失败", getErrorMessage(err), "error")
        },
      }
    )
  }

  const handleDeleteVariant = async (variant) => {
    const shouldDelete = await dialog({
      heading: "删除规格",
      text: "确认删除?",
    })

    if (shouldDelete) {
      return deleteVariant.mutate(variant.id)
    }
  }

  const handleDuplicateVariant = async (variant) => {
    createVariant.mutate(
      { ...variant },
      {
        onSuccess: () => {
          notification("成功", "创建规格成功", "success")
          setSelectedVariant(null)
          setIsDuplicate(false)
        },
        onError: (err) => {
          notification("失败", getErrorMessage(err), "error")
        },
      }
    )
  }

  const editVariantActions = (variant) => {
    return [
      {
        label: "编辑",
        icon: <EditIcon size={20} />,
        onClick: () => setSelectedVariant(variant),
      },
      {
        label: "复制",
        icon: <DuplicateIcon size={20} />,
        onClick: () => {
          setSelectedVariant(variant)
          setIsDuplicate(true)
        },
      },
      {
        label: "删除",
        icon: <TrashIcon size={20} />,
        onClick: () => handleDeleteVariant(variant),
        variant: "danger",
      },
    ]
  }

  const tableCellContent = (variant, col) => { 
    return (
      <>
        {
          col.field === 'image_url' ? (
            <Popover content={ <img className="w-[160px]" src={variant.image_url} alt="规格图" /> } >
              <img className="m-3 w-[60px] h-[60px]" src={variant.image_url} alt="规格图" />
            </Popover>
          ) : (
            <div className="px-2 py-4 truncate">
              {getDisplayValue(variant, col)}
            </div>
          )
        }
      </>
    )
  }

  const tableCellValue = (i, j, variant, col) => { 
    return (
      <>
        {
          col.field === 'image_url' ? (
            <Row gutter={10} className="my-2">
              <Col className="gutter-row">
                <img className="block w-[60px] h-[60px]" src={variant[col.field]||morentupian} alt="" />
              </Col>
              <Col className="gutter-row">
                <FileUploadField
                  onFileChosen={(files) => {
                    Medusa.uploads
                      .create([files[0]])
                      .then(({ data }) => {
                        const uploaded = data.uploads.map(({ url }) => url)
                        handleChange(i, col.field, uploaded[0])
                      })
                      .catch((err) => {
                        notification("Error uploading images", getErrorMessage(err), "error")
                      })
                  }}
                  text={ 
                    <div className="flex flex-col items-center">
                      <PlusOutlined className="text-[30px] text-[#9e9e9e] mb-2" />
                    </div>
                  }
                  filetypes={["png", "jpg", "jpeg"]}
                  className="py-large h-w-ration1 w-[60px] h-[60px]"
                />
              </Col>
            </Row>
          ) : (
            <GridInput
              key={j}
              value={variant[col.field]}
              onChange={({ currentTarget }) =>
                handleChange(i, col.field, currentTarget.value)
              }
            />
          )
        }
      </>
    )
  }

  return (
    <>
      <Table>
        <Table.Head>
          <Table.HeadRow>
            {columns.map((col) => (
              <Table.HeadCell className="w-[120px] px-2 py-4">
                <div className="flex items-center">
                  {col.header}
                  {col.tips && (<span className="ml-2"><IconTooltip content={col.tips} /></span>)}
                </div>
              </Table.HeadCell>
            ))}
          </Table.HeadRow>
        </Table.Head>
        <Table.Body>
          {variants.map((variant, i) => {
            return (
              <Table.Row
                key={i}
                color={"inherit"}
                actions={edit && editVariantActions(variant)}
              >
                {columns.map((col, j) => {
                  return (
                    <Table.Cell key={j}>
                      {edit || col.readOnly ? (
                        tableCellContent(variant, col)
                      ) : (
                        tableCellValue(i, j, variant, col)
                      )}
                    </Table.Cell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {selectedVariant && (
        <VariantEditor
          variant={selectedVariant}
          onCancel={() => setSelectedVariant(null)}
          onSubmit={isDuplicate ? handleDuplicateVariant : handleUpdateVariant}
          optionsMap={buildOptionsMap(product, selectedVariant)}
          title="编辑规格"
        />
      )}
    </>
  )
}

export default VariantGrid
