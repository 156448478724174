import {
  useAdminDeleteShippingOption,
  useAdminUpdateShippingOption,
} from "medusa-react"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../components/fundamentals/button"
import Input from "../../../components/molecules/input"
import Modal from "../../../components/molecules/modal"
import CurrencyInput from "../../../components/organisms/currency-input"
import DeletePrompt from "../../../components/organisms/delete-prompt"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"

const EditShipping = ({ shippingOption, region, onDone, onClick }) => {
  const { register, reset, handleSubmit, setValue } = useForm()
  const [adminOnly, setAdminOnly] = useState(shippingOption?.admin_only)
  const [showDelete, setShowDelete] = useState(false)
  const deleteOption = useAdminDeleteShippingOption(shippingOption.id)
  const updateOption = useAdminUpdateShippingOption(shippingOption.id)
  const notification = useNotification()

  useEffect(() => {
    if (shippingOption.requirements) {
      const minSubtotal = shippingOption.requirements.find(
        (req) => req.type === "min_subtotal"
      )
      if (minSubtotal) {
        shippingOption.requirements.min_subtotal = {
          amount: minSubtotal.amount,
          id: minSubtotal.id,
        }
      }
      const maxSubtotal = shippingOption.requirements.find(
        (req) => req.type === "max_subtotal"
      )
      if (maxSubtotal) {
        shippingOption.requirements.max_subtotal = {
          amount: maxSubtotal.amount,
          id: maxSubtotal.id,
        }
      }
    }

    reset({ ...shippingOption })
    register("amount")
    setValue("amount", shippingOption?.amount)

    register("requirements.min_subtotal.amount")
    setValue(
      "requirements.min_subtotal.amount",
      shippingOption?.requirements?.min_subtotal?.amount
    )

    register("requirements.max_subtotal.amount")
    setValue(
      "requirements.max_subtotal.amount",
      shippingOption?.requirements?.max_subtotal?.amount
    )
  }, [shippingOption])

  const handleDelete = async () => {
    deleteOption.mutate(void {}, {
      onSuccess: () => {
        onClick()
        if (onDone) {
          onDone()
        }
      },
      onError: (error) => {
        setShowDelete(false)
        notification("Error", getErrorMessage(error), "error")
      },
    })
  }

  const buildShippingRequirements = (
    requirements: { amount: number; type: string }[]
  ) => {
    if (!requirements) {
      return null
    }

    return Object.entries(requirements).reduce<
      (
        | { type: string; amount: number; id: string }
        | { type: string; amount: number }
      )[]
    >((acc, [key, value]) => {
      if (value.amount && value.amount > 0) {
        const reqType = shippingOption.requirements.find(
          (req) => req.type === key
        )
        if (reqType) {
          acc.push({
            type: key,
            amount: value.amount,
            id: reqType.id,
          })
        } else {
          acc.push({
            type: key,
            amount: value.amount,
          })
        }
        return acc
      } else {
        return acc
      }
    }, [])
  }

  const handleMinChange = (amount: number | undefined) => {
    if (amount) {
      setValue("requirements.min_subtotal.amount", amount)
    }
  }

  const handleMaxChange = (amount: number | undefined) => {
    if (amount) {
      setValue("requirements.max_subtotal.amount", amount)
    }
  }

  const handleAmountChange = (amount: number | undefined) => {
    if (amount) {
      setValue("amount", amount)
    }
  }

  const handleSave = (data: {
    requirements: { amount: number; type: string }[]
    name: any
    amount: number
  }) => {
    const reqs = buildShippingRequirements(data.requirements)

    const payload = {
      name: 'default',
      amount: data.amount,
      requirements: reqs ?? [],
      admin_only: adminOnly,
    }

    console.log('------payload-------', payload)

    // TODO: fix AdminPostShippingOptionsOptionReq type
    updateOption.mutate(payload, {
      onSuccess: () => {
        notification(
          "成功",
          "更新运费成功",
          "success"
        )
        if (onDone) {
          onDone()
        }
        onClick()
      },
      onError: (error) => {
        notification("Error", getErrorMessage(error), "error")
      },
    })
  }

  return (
    <>
      <Modal handleClose={onClick}>
        <form onSubmit={handleSubmit(handleSave)}>
          <Modal.Body>
            <Modal.Header handleClose={onClick}>
              <div>
                <h1 className="inter-xlarge-semibold">编辑运费</h1>
              </div>
            </Modal.Header>
            <Modal.Content>
              <div className="grid grid-cols-1 medium:grid-cols-1 gap-base">
                <CurrencyInput
                  readOnly
                  currentCurrency={region.currency_code}
                  size="small"
                >
                  <CurrencyInput.AmountInput
                    amount={shippingOption.amount}
                    label="价格"
                    onChange={(amount) => handleAmountChange(amount)}
                  />
                </CurrencyInput>
              </div>
            </Modal.Content>
            <Modal.Footer>
              <div className="flex items-center justify-end w-full gap-x-xsmall">
                <Button
                  type="button"
                  onClick={onClick}
                  variant="secondary"
                  size="small"
                  className="justify-center w-eventButton"
                >
                  取消
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="small"
                  className="justify-center w-eventButton"
                >
                  保存
                </Button>
              </div>
            </Modal.Footer>
          </Modal.Body>
        </form>
      </Modal>
    </>
  )
}

export default EditShipping
