import { useAdminCreateRegion, useAdminStore } from "medusa-react"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../components/fundamentals/button"
import Input from "../../../components/molecules/input"
import Modal from "../../../components/molecules/modal"
import Select from "../../../components/molecules/select"
import CurrencyInput from "../../../components/organisms/currency-input"
import useNotification from "../../../hooks/use-notification"
import { countries as countryData } from "../../../utils/countries"
import { getErrorMessage } from "../../../utils/error-messages"
import Medusa from '../../../services/api'

const NewRegion = ({ onDone, onClick }) => {
  const [currencies, setCurrencies] = useState<string[]>([])
  const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(
    undefined
  )
  const [countries, setCountries] = useState([])
  const [paymentOptions, setPaymentOptions] = useState([])
  const [paymentProviders, setPaymentProviders] = useState([])
  const [fulfillmentOptions, setFulfillmentOptions] = useState([])
  const [fulfillmentProviders, setFulfillmentProviders] = useState([])

  const { store, isLoading: storeIsLoading } = useAdminStore()
  const createRegion = useAdminCreateRegion()
  const { register, setValue, handleSubmit } = useForm()
  const notification = useNotification()

  useEffect(() => {
    if (storeIsLoading || !store) {
      return
    }
    // register({ name: "currency_code" })
    register("amount", { required: true })
    setCurrencies(store.currencies.map((currency) => currency.code))
    setPaymentOptions(
      store.payment_providers.map((c) => ({
        // Store Type is wrong, fix
        value: c.id,
        label: c.id,
      }))
    )
    setFulfillmentOptions(
      store.fulfillment_providers.map((c) => ({
        // Store Type is wrong, fix
        value: c.id,
        label: c.id,
      }))
    )
  }, [store, storeIsLoading])
  const handleAmountChange = (fieldName: string, amount?: number) => {
    setValue(fieldName, amount)
  }
  const handlePaymentChange = (values) => {
    setPaymentProviders(values)
    register({ name: "payment_providers" })
    setValue(
      "payment_providers",
      values.map((c) => c.value)
    )
  }

  const handleFulfillmentChange = (values) => {
    setFulfillmentProviders(values)
    register({ name: "fulfillment_providers" })
    setValue(
      "fulfillment_providers",
      values.map((c) => c.value)
    )
  }

  const handleChange = (values) => {
    setCountries(values)
    register({ name: "countries", required: true })
    setValue(
      "countries",
      values.map((c) => c.value)
    )
  }

  const onSave = (data) => {
    if (!data.countries?.length) {
      notification("错误", "请选择城市", "error")
      return
    }
    createRegion.mutate(
      {
        ...data,
        amount: Number(data.amount),
        currency_code: 'usd',
        tax_rate: (data.tax_rate||0) * 100,
        payment_providers: ['web3Payment'],
        fulfillment_providers: ['Web3Fulfillment']
      },
      {
        onSuccess: ({ region }) => {
          notification("成功", "创建地区成功", "success")
          if (onDone) {
            onDone(region.id)
          }
          onClick()
        },
        onError: (error) => {
          console.log('----error----', error)
          notification("Error", getErrorMessage(error), "error")
        },
      }
    )
  }

  // const countryOptions = countryData.map((c) => ({
  //   label: c.name,
  //   value: c.alpha2,
  // }))

  const [countryOptions, setCountryOptions] = useState<{ label: string; value: string; }[]>([])

  const handleChangeCurrency = (currency: string) => {
    setValue("currency_code", currency)
    setSelectedCurrency(currency)
  }

  useEffect(() => { 
    Medusa.setting.getRegionsCountry().then(res => { 
      const numericArr:number[] = res.data.data
      const arr1 = countryData.filter(item => !numericArr.includes(Number(item.numeric)))
      const arr2 = arr1.map((c) => ({
        label: c.name,
        value: c.alpha2,
      }))
      setCountryOptions(arr2)
      console.log('-----res-----', res)
    })
  }, [])
  
  return (
    <Modal handleClose={onClick}>
      <form onSubmit={handleSubmit(onSave)}>
        <Modal.Body className="w-[520px]">
          <Modal.Header handleClose={onClick}>
            <div>
              <h1 className="inter-xlarge-semibold">添加信息</h1>
            </div>
          </Modal.Header>
          <Modal.Content>
            <div>
              <p className="inter-base-semibold mb-base">基本信息</p>
              <div className="grid grid-cols-1 medium:grid-cols-1 gap-y-xsmall gap-x-base">
                <Input
                  name="name"
                  label="名称"
                  placeholder="地区名称"
                  ref={register({ required: true })}
                  className="mb-base min-w-[335px] w-full mb-0"
                />
                {/* <CurrencyInput
                  currencyCodes={currencies}
                  currentCurrency={selectedCurrency}
                  onChange={handleChangeCurrency}
                  className="items-baseline"
                /> */}
                <CurrencyInput
                  currentCurrency={"usd"}
                  readOnly
                  size="small"
                >
                  <CurrencyInput.AmountInput
                    label="价格"
                    onChange={(v) => handleAmountChange("amount", v)}
                    amount={undefined}
                  />
                </CurrencyInput>
                {/* <Input
                  placeholder="1000"
                  name="tax_code"
                  label="Tax Code"
                  ref={register}
                  className="mb-base min-w-[335px] w-full"
                /> */}
                <Select
                  isMultiSelect
                  enableSearch
                  label="国家"
                  placeholder="选择国家"
                  hasSelectAll
                  options={countryOptions}
                  value={countries}
                  onChange={handleChange}
                  clearSelected={true}
                  shouldToggleOnHover={ true }
                  className="mb-base min-w-[335px] w-full"
                />
              </div>
            </div>
            {/* <div className="mt-xlarge mb-small">
              <p className="inter-base-semibold mb-base">Providers</p>
              <div className="grid grid-cols-1 medium:grid-cols-2 gap-base">
                {!!paymentOptions.length && (
                  <Select
                    isMultiSelect
                    onChange={handlePaymentChange}
                    options={paymentOptions}
                    value={paymentProviders}
                    label="Payment Providers"
                    enableSearch
                  />
                )}
                {!!fulfillmentOptions.length && (
                  <Select
                    onChange={handleFulfillmentChange}
                    options={fulfillmentOptions}
                    value={fulfillmentProviders}
                    label="Fulfillment Providers"
                    enableSearch
                    isMultiSelect
                  />
                )}
              </div>
            </div> */}
          </Modal.Content>
          <Modal.Footer>
            <div className="flex items-center justify-end w-full gap-x-xsmall">
              <Button
                type="button"
                onClick={onClick}
                variant="secondary"
                size="small"
                className="justify-center w-eventButton"
              >
                取消
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="small"
                className="justify-center w-eventButton"
              >
                保存
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </form>
    </Modal>
  )
}

export default NewRegion
