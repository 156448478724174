import { capitalize } from "lodash"
import React from "react"
import { formatAmountWithSymbol } from "../../utils/prices"

export const useGridColumns = (product, isEditing) => {
  const defaultFields = [
    { header: "标题", field: "title", tips: "将显示在客户端的规格选项中" },
    { header: "SKU", field: "sku" },
    { header: "EAN", field: "ean" },
    { header: "库存数量", field: "inventory_quantity" },
    { header: "规格图", field: "image_url" },
  ]

  if (isEditing) {
    const optionColumns = product.options.map((o) => ({
      header: o.title,
      field: "options",
      formatter: (variantOptions) => {
        const displayVal = variantOptions.find((val) => val.option_id === o.id)
        return displayVal?.value || " - "
      },
    }))

    return [
      ...optionColumns,
      {
        header: "价格",
        field: "prices",
        formatter: (prices) => `${prices&&prices[0]?formatAmountWithSymbol({amount: prices[0].amount, currency: prices[0].currency_code}):'--'}`,
      },
      ...defaultFields,
    ]
  } else {
    return [
      {
        header: "规格",
        field: "options",
        formatter: (value) => {
          const options = value.map((v) => {
            if (v.value) {
              return capitalize(v.value)
            }
            return capitalize(v)
          })

          return options.join(" / ")
        },
        readOnly: true,
        headCol: true,
      },
      { header: "价格", field: "price" },
      ...defaultFields,
    ]
  }
}
