import React, { useEffect } from "react";
import Modal from "../../../components/molecules/modal";
import Button from "../../../components/fundamentals/button";
import InputField from "../../../components/molecules/input";
import Input from "../../../components/molecules/input"
import RadioGroup from "../../../components/organisms/radio-group"
import TextCountDown from '../../../components/countDown/textCountDown'
import { inputPrice } from '../../../utils/prices'

import { useForm, Controller } from "react-hook-form";

const SetAlarmModal = ({ handleClose, retaData, saveRate }) => { 
  const { register, reset, handleSubmit, control } = useForm();
  const submit = (value) => { 
    console.log('----value-----', value)
    saveRate(value)
  }
  useEffect(() => { 
    reset({...retaData})
  }, [retaData])
  return (
    <Modal handleClose={handleClose}>
      <Modal.Body>
        <Modal.Header handleClose={handleClose}>
          <span className="inter-xlarge-semibold">汇率告警设置</span>
        </Modal.Header>
        <Modal.Content>
          <div className="w-full">
            <Controller
              control={control}
              name="limit"
              render={({ onChange, value }) => {
                return (
                  <div className="flex items-center">
                    <span className="mr-2 text-grey-50">汇率告警限制：</span>
                    <RadioGroup.Root
                      value={value}
                      onValueChange={onChange}
                      defaultValue={ retaData?.status?'on':'off' }
                      className="flex items-center gap-4"
                    >
                      <RadioGroup.SimpleItem
                        label="开启"
                        value="on"
                      />
                      <RadioGroup.SimpleItem
                        label="关闭"
                        value="off"
                      />
                    </RadioGroup.Root>
                  </div>
                )
              }}
            />
            <Input
              className="mt-5 w-[80%]"
              id="value"
              label="1美元"
              name="value"
              placeholder="请输入最低兑换ETH限制"
              required
              ref={register({
                required: "请输入最低兑换ETH限制",
                minLength: 1,
              })}
              onInput={(val)=>val.value = inputPrice(val.value) }
            />
            {/* <div className="flex items-center mt-3">
              <Input
                id="message"
                className="w-[80%]"
                label="短信验证"
                name="message"
                placeholder="请输入短信验证码"
                required
                ref={register({
                  required: "请输入短信验证码",
                  minLength: 1,
                })}
              />
              <TextCountDown />
            </div>
            <div className="flex items-center mt-3">
              <Input
                id="goole"
                label="谷歌验证"
                className="w-[80%]"
                name="goole"
                placeholder="请输入谷歌验证码"
                required
                ref={register({
                  required: "请输入谷歌验证码",
                  minLength: 1,
                })}
              />
              <span className="text-[12px] text-[#298DF8] ml-3 underline cursor-pointer">粘贴</span>
            </div> */}
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex justify-end w-full">
            <Button
              variant="ghost"
              size="small"
              onClick={handleClose}
              className="mr-2"
            >
              取消
            </Button>
            <Button
              loading={false}
              variant="primary"
              className="min-w-[100px]"
              size="small"
              onClick={handleSubmit(submit)}
            >
              保存
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default SetAlarmModal