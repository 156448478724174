import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Input } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAdminCreateFulfillment, useAdminCreateShipment } from "medusa-react"
import useNotification from "../../../../hooks/use-notification"
import { getErrorMessage } from "../../../../utils/error-messages"
import Medusa from '../../../../services/api'

const { confirm } = Modal;
const logistics = ({ 
  order,
  orderId,
  uploadOrder,
}) => { 
  const createOrderFulfillment = useAdminCreateFulfillment(orderId)
  const markOrderShipped = useAdminCreateShipment(orderId)
  interface InfoModel { 
    company: string;
    orderNo: string;
  }
  let logisticsInfo: InfoModel | null = null
  const notification = useNotification()
  const onFinish = (values: InfoModel) => {
    logisticsInfo = values
    showConfirm()
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const showConfirm = () => {
    confirm({
      title: '确认保存发货信息?',
      icon: <ExclamationCircleOutlined />,
      content: '是否确认保存',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        Medusa['orders']['createFulfillments'](orderId, {
          logistics_company: logisticsInfo?.company,
          no_notification: false,
          tracking_number: logisticsInfo?.orderNo
        }).then(res => {
          if (res) { 
            order.fulfillment_status = res.data.order.fulfillment_status
            order.fulfillments.push({
              metadata: {
                logistics_company: res.data.order.fulfillments[0].metadata.logistics_company
              },
              tracking_numbers: [res.data.order.fulfillments[0].tracking_numbers[0]],
              tracking_links: [{tracking_number: logisticsInfo?.orderNo}]
            })
            notification("成功", '发货成功', "success")
            uploadOrder()
          }
        })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  // 先打包后发货
  const toCreateOrderFulfillment = () => { 
    const requestObj = {
      metadata: {
        logistics_company: logisticsInfo?.company
      },
      no_notification: false,
      items: order.items.map(item => ({ item_id: item.id, quantity: 0 }))
    }
    createOrderFulfillment.mutate(requestObj, {
      onSuccess: (res) => {
        toLogistics(res.order.fulfillments[0])
        notification("成功", '货物已打包', "success")
      },
      onError: (err) => notification("失败", getErrorMessage(err), "error"),
    })
  }
  const toLogistics = (fulfillment) => {
    markOrderShipped.mutate({
      fulfillment_id: fulfillment.id,
      tracking_numbers: [(logisticsInfo as InfoModel).orderNo],
      no_notification: false,
    }, {
      onSuccess: () => {
        notification("成功", '发货成功', "success")
      },
      onError: (err) => notification("Error", getErrorMessage(err), "error"),
    })
  }
  
  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="物流公司"
          name="company"
          rules={[{ required: true, message: '请输入物流公司名称!' }]}
        >
          <Input placeholder="请输入物流公司名称" />
        </Form.Item>
        <Form.Item
          label="物流单号"
          name="orderNo"
          rules={[{ required: true, message: '请输入物流单号!' }]}
        >
          <Input placeholder="请输入物流单号" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
          <Button type="primary" htmlType="submit" className="bg-[#1890ff] w-[120px]">
            发货
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default logistics