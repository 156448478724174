import React, { useEffect, useState } from "react";
import moment from "moment"

const COUNTDOWN_SECONDS = 60
const OrderPayDown = ({ leftTime, reload }) => { 
  // 计时中标识
  const [timing, setTiming] = useState(false);
  // 当前秒数
  const [second, setSecond] = useState(0);
  useEffect(() => {
    let timer;

    function countdown() {
      setSecond((preSecond) => {
        if (preSecond <= 1000) {
          setTiming(false);
          reload()
          return 0;
        } else {
          timer = setTimeout(countdown, 1000);
          return preSecond - 1000;
        }
      });
    }
    // 开始倒计时
    if (timing) {
      timer = setTimeout(countdown, 1000);
    }
    return () => clearTimeout(timer);
  }, [timing]);
  useEffect(() => { 
    if (leftTime) { 
      setSecond(leftTime)
      setTiming(true)
    }
  }, [leftTime])
  return (
    <>
      {
        timing ? (
          <span className="text-[13px] text-[#ff2525] mt-4">倒计时：{moment(second).format("mm:ss")}</span>
        ): (
          <span></span>
        )
      }
    </>
  )
}

export default OrderPayDown