import React, { useState, memo, useEffect } from "react";
import logoIcon from "../../assets/images/logo.png"
import CopyToClipboard from "../../components/atoms/copy-to-clipboard"
import AdminManageTable from './components/table'
import StaticCharts from "./components/staticChart";
import SetAlarmModal from './components/setAlarmModal'
import Medusa from '../../services/api'
import { price } from '../../utils/prices'
import { narrowTxt } from '../../utils/dealText'
import Tooltip from "../../components/atoms/tooltip"
import useNotification from "../../hooks/use-notification";

interface platformModel { 
  name: string;
  address?: string;
  balance?: string;
  income?: string;
  expend?: string;
  rate?: string;
}

const HeadTab = ({ tabList, currTab, toSetCurrTab }) => { 
  return (
    <div className="flex">
      {
        tabList.map(item =>
          <div
            className={`manage-head-tab ${item.value === currTab ? 'active' : ''}`}
            onClick={() => toSetCurrTab(item.value)}
            key={ item.value }>
            {item.name}
          </div>)
      }
    </div>
  )
}

const BaseInfoBox = ({ toSetShowAlarm, data, rateData }) => { 
  return (
    <div className="flex p-[20px] border border-[#e6e6ee] bg-[#fafafa] mt-[30px]">
      <img src={logoIcon} className="h-[67px] w-[67px] block" />
      <div className="flex flex-1 ml-[20px]">
        <div className="text-[12px] text-[#666] flex-1">平台：{ data?.name || 'ICONME' }</div>
        <div className="text-[12px] text-[#666] flex-1">
          <div className="flex items-center">
            <div className="mr-2">平台热钱包：
              {
                data?.address && data?.address.length > 14 ? (
                  <Tooltip content={ data.address }>
                    <span>{narrowTxt(data.address)}</span>
                  </Tooltip>
                ): (
                  <span>{data?.address || '--'}</span>     
                )
              }
            </div>
            {
              data?.address && (
                <CopyToClipboard
                  value={ data?.address }
                  showValue={false}
                  iconSize={14}
                />   
              )
            }
          </div>
        </div>
        <div className="text-[12px] text-[#666] flex-1">
          汇率告警：{ rateData?.status?'已开启':'已关闭'}
          <span className="ml-[5px] text-[#1989FA] cursor-pointer" onClick={toSetShowAlarm}>设置</span>
        </div>
      </div>
    </div>
  )
}

const PriceInfoBox = ({ data, unit }) => {
  return (
    <div className="border border-[#e6e6ee] flex p-[20px] mt-[30px]">
      <div className="flex-1 text-center">
        <p className="leading-[22px] text-[12px] text-[#a2a6b1]">平台{ unit.toUpperCase() }热钱包余额</p>
        <p className="mt-[6px] text-[#333] text-[30px]">
          {data.balance}
          <span className="text-[12px]">{unit.toUpperCase()}</span>
        </p>
      </div>
      <div className="flex-1 text-center">
        <p className="leading-[22px] text-[12px] text-[#a2a6b1]">平台热钱包累计收益金额</p>
        <p className="mt-[6px] text-[#333] text-[30px]">{ data.income }<span className="text-[12px]">{ unit.toUpperCase() }</span></p>
      </div>
      <div className="flex-1 text-center">
        <p className="leading-[22px] text-[12px] text-[#a2a6b1]">平台热钱包累计提现金额</p>
        <p className="mt-[6px] text-[#333] text-[30px]">{ data.expend }<span className="text-[12px]">{ unit.toUpperCase() }</span></p>
      </div>
    </div>
  )
}

const Manage = () => {
  const tabList = [
    {
      name: 'ETH',
      value: 'eth'
    },
    {
      name: 'USDT',
      value: 'usdt'
    },
    {
      name: 'USDC',
      value: 'usdc'
    },
    {
      name: 'DAI',
      value: 'dai'
    }
  ]
  const notification = useNotification();
  const [currTab, setCurrTab] = useState('eth')
  const [showAlarm, setShowAlarm] = useState(false)
  const [platformInfo, setPlatformInfo] = useState<platformModel | null>({
    name: 'ICONME',
    address: '',
    balance: '0',
    income: '0',
    expend: '0',

  })
  const [rateInfo, setRateInfo]=useState(null)
  const getPlatformInfo = () => { 
    Medusa['adminManage']['platform'](currTab).then(res => {
      const data = res?.data?.data || {}
      setPlatformInfo(()=>({
        ...platformInfo,
        name: data.name,
        address: data.address,
        balance: price(data.balance),
        income: price(data.income),
        expend: price(data.expend)
      }))
    })
  }
  const getRate = () => { 
    Medusa['adminManage']['getExchangeRateLimit'](currTab).then(res => {
      const data = res?.data?.data || {}
      setRateInfo(data)
    })
  }
  const setRate = (value) => { 
    Medusa['adminManage']['setExchangeRateLimit']({
      currency: currTab,
      status: value.limit === 'off' ? 0 : 1,
      value: Number(value.value) 
    }).then(res => { 
      notification("成功", "设置汇率告警成功", "success");
      setShowAlarm(false)
      getRate()
    })
  }
  useEffect(() => { 
    getPlatformInfo()
    getRate()
  }, [currTab])
  return (
    <>
      <HeadTab tabList={tabList} currTab={currTab} toSetCurrTab={setCurrTab} />
      <BaseInfoBox data={platformInfo} rateData={ rateInfo } toSetShowAlarm={ ()=>setShowAlarm(true) } />
      <PriceInfoBox data={platformInfo} unit={ currTab } />
      {/* <StaticCharts /> */}
      <AdminManageTable unit={ currTab } />
      {
        showAlarm && (
          <SetAlarmModal retaData={rateInfo} handleClose={() => setShowAlarm(false)} saveRate={ setRate }></SetAlarmModal>
        )
      }
    </>
  )
}

export default Manage