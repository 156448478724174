import moment from "moment"
import clsx from "clsx"
import React, { useMemo } from "react"
import Tooltip from "../../atoms/tooltip"

const useCollectionTableColumn = () => {
  const columns = useMemo(
    () => [
      {
        Header: "分类名",
        accessor: "title",
        Cell: ({ row: { original } }) => {
          return <div className="flex items-center">{original.title}</div>
        },
      },
      {
        Header: "标识符",
        accessor: "handle",
        Cell: ({ cell: { value } }) => <div>/{value}</div>,
      },
      {
        Header: "创建于",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          <div>{ moment(value).format("YYYY-MM-DD HH:mm:ss") }</div>
        ),
      },
      {
        Header: "更新于",
        accessor: "updated_at",
        Cell: ({ cell: { value } }) => (
          <div>{ moment(value).format("YYYY-MM-DD HH:mm:ss") }</div>
        ),
      },
      {
        Header: "产品",
        accessor: "products",
        Cell: ({ cell: { value } }) => {
          return <div>{value?.length || "-"}</div>
        },
      }
    ],
    []
  )

  return [columns]
}

export default useCollectionTableColumn
