import moment from "moment"
import React, { useMemo } from "react"
import ReactCountryFlag from "react-country-flag"
import { getColor } from "../../../utils/color"
import { isoAlpha2Countries } from "../../../utils/countries"
import { formatAmountWithSymbol, priceAmount, countPriceRate } from "../../../utils/prices"
import Tooltip from "../../atoms/tooltip"
import StatusDot from "../../fundamentals/status-indicator"
import CustomerAvatarItem from "../../molecules/customer-avatar-item"
import Table from "../../molecules/table"
import { EditOutlined } from '@ant-design/icons';
import { narrowTxt } from '../../../utils/dealText'
import { OrderStatusTemplate } from '../../../domain/orders/details/templates'

const useOrderTableColums = (getRemark, getId) => {
  const decideStatus = (status) => {
    switch (status) {
      case "captured":
        return <StatusDot variant="success" title={"Paid"} />
      case "awaiting":
        return <StatusDot variant="default" title={"Awaiting"} />
      case "requires_action":
        return <StatusDot variant="danger" title={"Requires action"} />
      case "canceled":
        return <StatusDot variant="warning" title={"Canceled"} />
      default:
        return <StatusDot variant="primary" title={"N/A"} />
    }
  }

  const dealOrderStatus = (order) => {
    // 订单状态 待付款，待发货，已发货，订单异常
    if (['not_paid', 'awaiting'].includes(order.payment_status)) {
      return '待支付'
    } else if (order.payment_status === 'captured') {
      if (['fulfilled', 'not_fulfilled'].includes(order.fulfillment_status)) {
        return '待发货'
      } else if (order.fulfillment_status === 'shipped') {
        return '已发货'
      } else {
        return `发货状态${order.fulfillment_status}`
      }
    } else {
      return `支付状态${order.payment_status}`
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: <Table.HeadCell className="pl-2">序号</Table.HeadCell>,
        accessor: "display_id",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell
            key={index}
            className="text-grey-90 group-hover:text-violet-60 min-w-[100px] pl-2"
          >{`#${value}`}</Table.Cell>
        ),
      },
      {
        Header: "订单号",
        accessor: "id",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index} className="pr-2">
            { value }
          </Table.Cell>
        ),
      },
      {
        Header: "日期",
        accessor: "created_at",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index} className="pr-2">
            {moment(value).format("YYYY-MM-DD HH:mm:ss")}
          </Table.Cell>
        ),
      },
      {
        Header: "顾客",
        accessor: "shipping_address",
        Cell: ({ row, cell: { value }, index }) => (
          <Table.Cell key={index}>
            <CustomerAvatarItem
              customer={{
                first_name: value?.first_name,
                last_name: value?.last_name,
              }}
              color={getColor(row.index)}
            />
          </Table.Cell>
        ),
      },
      {
        Header: () => <div className="w-[100px]">订单状态</div> ,
        accessor: "fulfillment_status",
        Cell: ({ row, cell: { value }, index }) => (
          <Table.Cell key={index}>{OrderStatusTemplate({
            fulfillmentStatus: row.original.fulfillment_status,
            paymentStatus: row.original.payment_status,
            orderStatus: row.original.status,
          })}</Table.Cell>
        ),
      },
      {
        Header: () => <div className="w-[100px]">订单金额</div>,
        accessor: "total",
        Cell: ({ row, cell: { value }, index }) => (
          <Table.Cell key={index}>
            <div>
              {formatAmountWithSymbol({
                amount: value,
                currency: row.original.currency_code,
                digits: 2,
              })}
            </div>
          </Table.Cell>
        ),
      },
      {
        Header: ()=><div className="w-[100px]">付款金额</div>,
        accessor: "transaction",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index} className="w-[5%]">
            <div>{value?priceAmount(value.value, value.token_symbol, value.token_decimal):'--'}</div>
          </Table.Cell>
        ),
      },
      // {
      //   Header: ()=><div>费率</div>,
      //   accessor: "transaction",
      //   Cell: ({ cell: { value }, index }) => (
      //     <Table.Cell key={index} className="w-[5%]">
      //       <div>{value?priceAmount(value.value, value.token_name, value.token_decimal):'--'}</div>
      //     </Table.Cell>
      //   ),
      // },
      {
        Header: ()=><div className="w-[100px]">汇率</div>,
        accessor: "exchange_rate",
        Cell: ({ row, cell: { value }, index }) => (
          <Table.Cell key={index}>
            {
              value || value === 0 ? (
                <div>1美元/{countPriceRate(value) + ' ' + row.original.pay_currency?.toUpperCase()}</div>
              ) : (
                <div>--</div>
              )
            }
          </Table.Cell>
        ),
      },
      {
        Header: ()=><div className="w-[150px]">备注</div>,
        accessor: "remark",
        Cell: ({ row, cell: { value }, index }) => (
          <Table.Cell key={index}>
            <div>
              {
                value&&value.length>14 ? (
                  <Tooltip content={value}>
                    <span className="mr-2">{narrowTxt(value)}</span>
                  </Tooltip>
                ) : (
                  <span className="mr-2">{value || '--'}</span>
                )
              }
              <EditOutlined size={14} onClick={(e) => {
                e.stopPropagation()
                getRemark({
                  remark: value
                })
                getId(row.original.id)
              }} />
            </div>
          </Table.Cell>
        ),
      }
      // {
      //   Header: "",
      //   accessor: "country_code",
      //   Cell: ({ row, index }) => (
      //     <Table.Cell className="w-[5%] pr-2" key={index}>
      //       <div className="flex justify-end w-full rounded-rounded">
      //         <Tooltip
      //           content={
      //             isoAlpha2Countries[
      //               row.original.shipping_address.country_code.toUpperCase()
      //             ] || row.original.shipping_address.country_code.toUpperCase()
      //           }
      //         >
      //           <ReactCountryFlag
      //             className={"rounded"}
      //             svg
      //             countryCode={row.original.shipping_address.country_code}
      //           />
      //         </Tooltip>
      //       </div>
      //     </Table.Cell>
      //   ),
      // },
    ],
    []
  )

  return [columns]
}

export default useOrderTableColums
