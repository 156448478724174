import { graphql, useStaticQuery, navigate } from "gatsby"
import { Address, ClaimOrder, Fulfillment, Swap } from "@medusajs/medusa"
import { capitalize, sum } from "lodash"
import Decimal from "decimal.js";
import {
  useAdminCancelOrder,
  useAdminCapturePayment,
  useAdminOrder,
  useAdminRegion,
  useAdminUpdateOrder,
} from "medusa-react"
import moment from "moment"
import React, { useEffect, useMemo, useState, useContext } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import ReactJson from "react-json-view"
import Avatar from "../../../components/atoms/avatar"
import CopyToClipboard from "../../../components/atoms/copy-to-clipboard"
import Spinner from "../../../components/atoms/spinner"
import Tooltip from "../../../components/atoms/tooltip"
import Badge from "../../../components/fundamentals/badge"
import Button from "../../../components/fundamentals/button"
import DetailsIcon from "../../../components/fundamentals/details-icon"
import CancelIcon from "../../../components/fundamentals/icons/cancel-icon"
import ClipboardCopyIcon from "../../../components/fundamentals/icons/clipboard-copy-icon"
import CornerDownRightIcon from "../../../components/fundamentals/icons/corner-down-right-icon"
import DollarSignIcon from "../../../components/fundamentals/icons/dollar-sign-icon"
import MailIcon from "../../../components/fundamentals/icons/mail-icon"
import TruckIcon from "../../../components/fundamentals/icons/truck-icon"
import Breadcrumb from "../../../components/molecules/breadcrumb"
import BodyCard from "../../../components/organisms/body-card"
import RawJSON from "../../../components/organisms/raw-json"
import Timeline from "../../../components/organisms/timeline"
import useClipboard from "../../../hooks/use-clipboard"
import useImperativeDialog from "../../../hooks/use-imperative-dialog"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"
import { formatAmountWithSymbol, priceAmount, countPriceRate } from "../../../utils/prices"
import AddressModal from "./address-modal"
import CreateFulfillmentModal from "./create-fulfillment"
import UpdateFulfillment from './update-fulfillment'
import Logistics from './logistics'
import EmailModal from "./email-modal"
import RemarkModal from "./remark-modal"
import MarkShippedModal from "./mark-shipped"
import OrderLine from "./order-line"
import CreateRefundModal from "./refund"
import { EditOutlined } from '@ant-design/icons';
import { narrowTxt } from '../../../utils/dealText'
import OrderPayDown from "../../../components/countDown/orderPayDown"
import { AccountContext } from "../../../context/account";
import {
  DisplayTotal,
  FormattedAddress,
  FormattedFulfillment,
  FulfillmentStatusComponent,
  OrderStatusComponent,
  OrderStatusTemplate,
  PaymentActionables,
  PaymentDetails,
  PaymentStatusComponent,
} from "./templates"

type OrderDetailFulfillment = {
  title: string
  type: string
  fulfillment: Fulfillment
  swap?: Swap
  claim?: ClaimOrder
}

const CANCEL_TIME = 10 * 60 * 1000

const gatherAllFulfillments = (order) => {
  if (!order) {
    return []
  }

  const all: OrderDetailFulfillment[] = []

  order.fulfillments.forEach((f, index) => {
    all.push({
      title: `Fulfillment #${index + 1}`,
      type: "default",
      fulfillment: f,
    })
  })

  if (order.claims?.length) {
    order.claims.forEach((claim) => {
      if (claim.fulfillment_status !== "not_fulfilled") {
        claim.fulfillments.forEach((fulfillment, index) => {
          all.push({
            title: `Claim fulfillment #${index + 1}`,
            type: "claim",
            fulfillment,
            claim,
          })
        })
      }
    })
  }

  if (order.swaps?.length) {
    order.swaps.forEach((swap) => {
      if (swap.fulfillment_status !== "not_fulfilled") {
        swap.fulfillments.forEach((fulfillment, index) => {
          all.push({
            title: `Swap fulfillment #${index + 1}`,
            type: "swap",
            fulfillment,
            swap,
          })
        })
      }
    })
  }

  return all
}

const OrderDetails = ({ id }) => {
  const dialog = useImperativeDialog()
  const account = useContext(AccountContext);
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            networkUrl
          }
        }
      }
    `
  )

  const [addressModal, setAddressModal] = useState<null | {
    address: Address
    type: "billing" | "shipping"
  }>(null)

  const [emailModal, setEmailModal] = useState<null | {
    email: string
  }>(null)

  const [remarkModal, setRemarkModal] = useState<null | {
    remark: string
  }>(null)

  const [showFulfillment, setShowFulfillment] = useState(false)
  const [showRefund, setShowRefund] = useState(false)
  const [fullfilmentToShip, setFullfilmentToShip] = useState(null)
  const [showUpdateFulfillment, setShowUpdateFulfillment] = useState(false)

  const { order, isLoading, refetch } = useAdminOrder(id)

  const capturePayment = useAdminCapturePayment(id)
  const cancelOrder = useAdminCancelOrder(id)
  const updateOrder = useAdminUpdateOrder(id)

  const [shippedStatus, setShippedStatus] = useState(order?.fulfillment_status)

  // @ts-ignore
  const { region } = useAdminRegion(order?.region_id, {
    enabled: !!order?.region_id,
  })

  const notification = useNotification()

  const [, handleCopy] = useClipboard(`${order?.id!}`, {
    successDuration: 5500,
    onCopied: () => notification("成功", "复制订单号成功", "success"),
  })

  const [, handleCopyEmail] = useClipboard(order?.shipping_address?.email!, {
    successDuration: 5500,
    onCopied: () => notification("成功", "复制邮箱成功", "success"),
  })

  // @ts-ignore
  useHotkeys("esc", () => navigate("/a/orders"))
  useHotkeys("command+i", handleCopy)

  const {
    hasMovements,
    swapAmount,
    manualRefund,
    swapRefund,
    returnRefund,
    currSurTime,
  } = useMemo(() => {
    let manualRefund = 0
    let swapRefund = 0
    let returnRefund = 0

    const swapAmount = sum(order?.swaps.map((s) => s.difference_due) || [0])
    setShippedStatus(order?.fulfillment_status)
    if (order?.refunds?.length) {
      order.refunds.forEach((ref) => {
        if (ref.reason === "other" || ref.reason === "discount") {
          manualRefund += ref.amount
        }
        if (ref.reason === "return") {
          returnRefund += ref.amount
        }
        if (ref.reason === "swap") {
          swapRefund += ref.amount
        }
      })
    }
    return {
      hasMovements: swapAmount + manualRefund + swapRefund + returnRefund !== 0,
      swapAmount,
      manualRefund,
      swapRefund,
      returnRefund,
      currSurTime: order?.now - new Date(order?.created_at as any).getTime() > CANCEL_TIME ? 0 : CANCEL_TIME - order?.now + new Date(order?.created_at as any).getTime()
    }
  }, [order])

  useEffect(() => {
    refetch()
  }, [])

  const changeOrderData = () => {
    setShippedStatus('shipped')
    refetch()
  }

  const handleDeleteOrder = async () => {
    const shouldDelete = await dialog({
      heading: "取消订单",
      text: "你确定要取消订单?",
    })

    if (!shouldDelete) {
      return
    }

    return cancelOrder.mutate(void {}, {
      onSuccess: () =>
        notification("成功", "取消订单成功", "success"),
      onError: (err) => notification("失败", getErrorMessage(err), "error"),
    })
  }

  const handleUpdateAddress = async ({ data, type }) => {
    const updateObj = {}
    // const email = data.email
    // delete data.email
    if (type === "shipping") {
      updateObj["shipping_address"] = {
        ...data,
      }
    } else {
      updateObj["billing_address"] = {
        ...data,
      }
    }
    return updateOrder.mutate(updateObj, {
      onSuccess: () => {
        notification("成功", "更新地址成功", "success")
        setAddressModal(null)
      },
      onError: (err) => notification("Error", getErrorMessage(err), "error"),
    })
  }

  const handleUpdateEmail = async ({ email }) => {
    const updateObj = email ? { email } : {}

    return updateOrder.mutate(updateObj, {
      onSuccess: () => {
        notification(
          "Success",
          "Successfully updated the email address",
          "success"
        )
        setEmailModal(null)
      },
      onError: (err) => notification("Error", getErrorMessage(err), "error"),
    })
  }

  const allFulfillments = gatherAllFulfillments(order)

  const customerActionables = [
    {
      label: "编辑送货地址",
      icon: <TruckIcon size={"20"} />,
      onClick: () =>
        setAddressModal({
          address: order?.shipping_address,
          type: "shipping",
        }),
    },
    {
      label: "查看客户信息",
      icon: <DetailsIcon size={"20"} />,
      onClick: () => {
        if (account.roles.length) {
          let arr:any[] = [];
          account.roles.forEach((item) => {
            arr = Array.from(new Set([...arr, ...item.permissions]));
          });
          if (arr.includes('4')) {
            navigate(`/a/customers/${order?.customer.id}`)
          } else {
            notification("错误", "暂无访问权限，请联系管理员", "error");
          }
        }
      },
    },
  ]

  // if (order?.billing_address) {
  //   customerActionables.push({
  //     label: "Edit Billing Address",
  //     icon: <DollarSignIcon size={"20"} />,
  //     onClick: () => {
  //       if (order.billing_address) {
  //         setAddressModal({
  //           address: order?.billing_address,
  //           type: "billing",
  //         })
  //       }
  //     },
  //   })
  // }

  if (order?.email) {
    customerActionables.push({
      label: "编辑邮箱地址",
      icon: <MailIcon size={"20"} />,
      onClick: () => {
        setEmailModal({
          email: order?.email,
        })
      },
    })
  }
  const toEtherscan = (address) => {
    if (address) {
      if (window !== undefined) {
        window.open(`${site.siteMetadata.networkUrl + address}`)
      }
    }
  }
  // 计算订单价格 美元价格转化成虚拟货币价格 美元/100*汇率
  const countVirtPrice = (amount, rate) => {
    amount = amount ? Number(amount) : 0
    rate = rate ? Number(rate) : 1
    const countRate = Number(countPriceRate(rate))
    return new Decimal(amount).div(new Decimal(100)).mul(new Decimal(countRate))
  }

  // 发货状态
  const showEditLogistics = () => {
    return order?.status === 'pending' && (['not_fulfilled', 'partially_fulfilled'].includes(order?.fulfillment_status)) && order?.payment_status === 'captured'
  }
  // 待支付状态（可以取消订单）
  const orderIsPay = () => {
    return (order?.status === 'pending' && (['not_fulfilled', 'partially_fulfilled'].includes(order?.fulfillment_status)) && order?.payment_status === 'awaiting')
  }

  return (
    <div>
      <Breadcrumb
        currentPage={"订单详细信息"}
        previousBreadcrumb={"订单"}
        previousRoute="/a/orders"
      />
      {isLoading || !order ? (
        <BodyCard className="flex items-center justify-center w-full pt-2xlarge">
          <Spinner size={"large"} variant={"secondary"} />
        </BodyCard>
      ) : (
        <div className="flex space-x-4">
          <div className="flex flex-col w-7/12 h-full">
            <BodyCard
              className={"w-full mb-4 min-h-[200px]"}
              customHeader={
                <Tooltip side="top" content={"复制订单号"}>
                  <button
                    className="flex items-center cursor-pointer inter-xlarge-semibold text-[20px] text-grey-90 active:text-violet-90 gap-x-2"
                    onClick={handleCopy}
                  >
                    #{order.id} <ClipboardCopyIcon size={16} />
                  </button>
                </Tooltip>
              }
              subtitle={moment(order.created_at).format("YYYY年MM月DD日 HH:mm:ss")}
              status={
                <OrderStatusTemplate
                  fulfillmentStatus={order?.fulfillment_status}
                  paymentStatus={order?.payment_status}
                  orderStatus={order?.status} />}
              forceDropdown={orderIsPay()||order?.status==='abnormal'?true:false}
              actionables={orderIsPay()||order?.status==='abnormal'? [
                {
                  label: "取消订单",
                  icon: <CancelIcon size={"20"} />,
                  variant: "danger",
                  onClick: () => handleDeleteOrder(),
                },
              ]:[]}
            >
              <div className="flex mt-6 space-x-6 divide-x">
                <div className="flex flex-col">
                  <div className="mb-1 inter-smaller-regular text-grey-50">
                    电子邮箱
                  </div>
                  <button
                    className="flex items-center cursor-pointer text-grey-90 active:text-violet-90 gap-x-1"
                    onClick={handleCopyEmail}
                  >
                    {order?.shipping_address?.email}
                    <ClipboardCopyIcon size={12} />
                  </button>
                </div>
                <div className="flex flex-col pl-6">
                  <div className="mb-1 inter-smaller-regular text-grey-50">
                    电话
                  </div>
                  <div>{order?.shipping_address?.phone || "--"}</div>
                </div>
                <div className="flex flex-col pl-6">
                  <div className="mb-1 inter-smaller-regular text-grey-50">
                    备注
                  </div>
                    <div>
                      {
                        order?.remark && order?.remark.length > 14 ? (
                          <Tooltip content={order?.remark}>
                            {narrowTxt(order?.remark)}
                          </Tooltip>
                        ): (
                          <span>{order?.remark || "--"}</span>
                        )
                      }
                    <EditOutlined className="ml-2" size={14} onClick={() => { setRemarkModal({remark: order?.remark}) } } />
                  </div>
                </div>
                {/* <div className="flex flex-col pl-6">
                  <div className="mb-1 inter-smaller-regular text-grey-50">
                    Payment
                  </div>
                  <div>
                    {order?.payments
                      ?.map((p) => capitalize(p.provider_id))
                      .join(", ")}
                  </div>
                </div> */}
              </div>
              {
                (order.status === 'pending'&&order.payment_status==='awaiting'&&currSurTime) && (
                  <OrderPayDown
                  leftTime={currSurTime}
                  reload={ () => refetch() }></OrderPayDown>
                )
              }
            </BodyCard>
            <BodyCard className={"w-full mb-4 min-h-0 h-auto"} title="订单">
              <div className="mt-6">
                {order?.items?.map((item, i) => (
                  <OrderLine
                    key={i}
                    item={item}
                    currencyCode={order?.currency_code}
                  />
                ))}
                <DisplayTotal
                  currency={order?.currency_code}
                  totalAmount={order?.subtotal}
                  totalTitle={"商品总价"}
                />
                {order?.discounts?.map((discount, index) => (
                  <DisplayTotal
                    key={index}
                    currency={order?.currency_code}
                    totalAmount={-1 * order?.discount_total}
                    totalTitle={
                      <div className="flex items-center inter-small-regular text-grey-90">
                        折扣:{" "}
                        <Badge className="ml-3" variant="default">
                          {discount.code}
                        </Badge>
                      </div>
                    }
                  />
                ))}
                {order?.gift_cards?.map((giftCard, index) => (
                  <DisplayTotal
                    key={index}
                    currency={order?.currency_code}
                    totalAmount={-1 * order?.gift_card_total}
                    totalTitle={
                      <div className="flex items-center inter-small-regular text-grey-90">
                        Gift card:{" "}
                        <Badge className="ml-3" variant="default">
                          {giftCard.code}
                        </Badge>
                        <div className="ml-2">
                          <CopyToClipboard
                            value={giftCard.code}
                            showValue={false}
                            iconSize={16}
                          />
                        </div>
                      </div>
                    }
                  />
                ))}
                <DisplayTotal
                  currency={order?.currency_code}
                  totalAmount={order?.shipping_total}
                  totalTitle={"运费"}
                />
                <DisplayTotal
                  currency={order?.currency_code}
                  totalAmount={order?.tax_total}
                  totalTitle={`关税`}
                />
                <DisplayTotal
                  variant={"large"}
                  currency={order?.currency_code}
                  totalAmount={order?.total}
                  totalTitle={hasMovements ? "Original Total" : "合计"}
                />
                <PaymentDetails
                  manualRefund={manualRefund}
                  swapAmount={swapAmount}
                  swapRefund={swapRefund}
                  returnRefund={returnRefund}
                  paidTotal={order?.paid_total}
                  refundedTotal={order?.refunded_total}
                  currency={order?.currency_code}
                />
              </div>
            </BodyCard>
            {
              order?.transaction && (
                <BodyCard
                  className={"w-full mb-4 min-h-0 h-auto"}
                  title="支付"
                >
                  <div className="mt-6">
                    {order?.payments.map((payment) => (
                      <div className="flex flex-col">
                        <div className="flex items-center justify-between mt-4">
                          <div className="text-grey-90 inter-small-regular">订单金额</div>
                          <div>
                            <span className="text-grey-90 inter-small-regular">{ countVirtPrice(payment?.amount, order?.exchange_rate) + ' ' + (order?.transaction?.token_symbol?order.transaction.token_symbol.toUpperCase():'')}</span>
                          </div>
                        </div>
                        <div className="flex items-center justify-between mt-4">
                          <div className="text-grey-90 inter-small-regular">支付金额</div>
                          <div>
                            <span className="text-grey-90 inter-small-regular">{ order?.transaction?priceAmount(order?.transaction.value,order?.transaction.token_symbol,order?.transaction.token_decimal):'--' }</span>
                          </div>
                        </div>
                        <div className="flex items-center justify-between mt-4">
                          <div className="text-grey-90 inter-small-regular">美元订单价格</div>
                          <div>
                            <span className="text-grey-90 inter-small-regular">{ payment?.amount/100 } 美元</span>
                          </div>
                        </div>
                        <div className="flex items-center justify-between mt-4">
                          <div className="text-grey-90 inter-small-regular">汇率</div>
                          <div>
                            <span className="text-grey-90 inter-small-regular">1美元/{ countPriceRate(order?.exchange_rate) + order?.transaction?.token_symbol?.toUpperCase() }</span>
                          </div>
                        </div>
                        <div className="flex items-center justify-between mt-4">
                          <div className="text-grey-90 inter-small-regular">链上交易ID</div>
                          <div className="max-w-[70%] truncate" onClick={()=>toEtherscan(order?.transaction?.id)}>
                            {
                              order?.transaction?.id && order?.transaction?.id?.length > 14?(
                                <Tooltip content={ order?.transaction?.id }>
                                  <span className="text-[#007BD3] inter-small-regular cursor-pointer">{ narrowTxt(order?.transaction?.id) }</span>
                                </Tooltip>
                              ) : (
                                <span className="text-[#007BD3] inter-small-regular cursor-pointer">{ order?.transaction?.id || '--' }</span>
                              )
                            }

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </BodyCard>
              )
            }

            {
              orderIsPay() || order?.status === 'canceled' ? (
                <BodyCard className={"w-full mb-4 min-h-0 h-auto"} title={`物流`}>
                  <div className="py-[40px] text-grey-50 text-[13px] text-center">暂无物流信息</div>
                </BodyCard>
                ) : (
                <BodyCard
                  className={"w-full mb-4 min-h-0 h-auto"}
                  title={ `物流` }
                  customActionable={
                    order.fulfillment_status === "shipped" && (
                      <Button
                        variant="secondary"
                        size="small"
                        onClick={() => setShowUpdateFulfillment(true)}
                      >
                        修改物流信息
                      </Button>
                    )
                  }
                >
                  {
                    shippedStatus === "shipped" ? (
                      <>
                        <div className="text-grey-90 inter-small-regular">物流公司：{ order?.fulfillments[0]?.metadata.logistics_company||'--' }</div>
                        <div className="mt-3 text-grey-90 inter-small-regular">物流单号：{ order?.fulfillments[0]?.tracking_links[0]?.tracking_number||'--' }</div>
                      </>
                    ) : (
                      <Logistics
                        order={order}
                        orderId={order.id}
                        uploadOrder={ ()=>changeOrderData() }
                      ></Logistics>
                    )
                  }
                </BodyCard>
              )
            }
            <BodyCard
              className={"w-full mb-4 min-h-0 h-auto"}
              title="顾客"
              actionables={customerActionables}
            >
              <div className="mt-6">
                <div className="flex items-center w-full space-x-4">
                  <div className="flex w-[40px] h-[40px] ">
                    <Avatar
                      user={order?.customer}
                      font="inter-large-semibold"
                      color="bg-fuschia-40"
                    />
                  </div>
                  <div>
                    <h1 className="inter-large-semibold text-grey-90">
                      {`${order?.shipping_address?.first_name} ${order?.shipping_address?.last_name}`}
                    </h1>
                    <span className="inter-small-regular text-grey-50">
                      {order?.shipping_address?.city},{" "}
                      {order?.shipping_address?.country_code}
                    </span>
                  </div>
                </div>
                <div className="flex mt-6 space-x-6 divide-x">
                  <div className="flex flex-col">
                    <div className="mb-1 inter-small-regular text-grey-50">
                      联系方式
                    </div>
                    <div className="flex flex-col inter-small-regular">
                      <span>{order?.shipping_address?.email}</span>
                      <span>{order?.shipping_address?.phone || ""}</span>
                    </div>
                  </div>
                  <FormattedAddress
                    title={"送货地址"}
                    addr={order?.shipping_address}
                  />
                  {/* <FormattedAddress
                    title={"Billing"}
                    addr={order?.billing_address}
                  /> */}
                </div>
              </div>
            </BodyCard>
          </div>
          <Timeline orderId={order.id} />
        </div>
      )}
      {addressModal && (
        <AddressModal
          handleClose={() => setAddressModal(null)}
          handleSave={(obj) => handleUpdateAddress(obj)}
          address={addressModal.address}
          type={addressModal.type}
          allowedCountries={region?.countries}
        />
      )}
      {emailModal && (
        <EmailModal
          handleClose={() => setEmailModal(null)}
          handleSave={(obj) => handleUpdateEmail(obj)}
          email={emailModal.email}
        />
      )}
      {remarkModal && (
        <RemarkModal
          handleClose={() => setRemarkModal(null)}
          handleSave={(obj) => handleUpdateEmail(obj)}
          remark={remarkModal.remark}
          id={ id }
        />
      )}
      {showFulfillment && order && (
        <CreateFulfillmentModal
          orderToFulfill={order as any}
          handleCancel={() => setShowFulfillment(false)}
          orderId={order.id}
        />
      )}
      {showRefund && order && (
        <CreateRefundModal
          order={order}
          onDismiss={() => setShowRefund(false)}
        />
      )}
      {fullfilmentToShip && order && (
        <MarkShippedModal
          orderToShip={order as any}
          handleCancel={() => setFullfilmentToShip(null)}
          fulfillment={fullfilmentToShip}
          orderId={order.id}
        />
      )}
      <UpdateFulfillment
        isModalVisible={showUpdateFulfillment}
        order={order}
        handleOk={ () => setShowUpdateFulfillment(false) }
        handleCancel={() => setShowUpdateFulfillment(false)}
      />
    </div>
  )
}

export default OrderDetails
