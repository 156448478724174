import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { useNavigate } from "@reach/router";
import BreadCrumb from "../../../components/molecules/breadcrumb"
import Medusa from "../../../services/api"
import BodyCard from "../../../components/organisms/body-card"
import InviteModal from "../../../components/organisms/invite-modal"
import PlusIcon from "../../../components/fundamentals/icons/plus-icon"
import UserTable from "../../../components/templates/user-table"
import MemberTable from "../../../components/templates/member-table";
import RoleTable from "../../../components/templates/role-table";
import AddRoleModal from "./components/addRoleModal";

const Users: React.FC = ({ children }) => {
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showAddRoleModal, setShowAddRoleModal] = useState(false)
  const [roleId, setRoleId] = useState(null)
  const [userChange, setUserChange] = useState(0)
  const [roleChange, setRoleChange] = useState(0)

  const navigate = useNavigate()

  const showLabel = (k) => { 
    const labelObj = {
      member: '成员',
      role: '角色',
    }
    return labelObj[k] || k
  }

  const [activeView, setActiveView] = useState('member')

  const toEditRole = (id) => { 
    setShowAddRoleModal(true)
    setRoleId(id)
  }

  const actionables = [
    activeView === 'member' ? {
      label: "邀请用户",
      onClick: () => setShowInviteModal(true),
      icon: (
        <span className="text-grey-90">
          <PlusIcon size={20} />
        </span>
      ),
    } : {
      label: "添加角色",
      onClick: () => setShowAddRoleModal(true),
      icon: (
        <span className="text-grey-90">
          <PlusIcon size={20} />
        </span>
      ),
    },
  ]

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col w-full grow">
        <BreadCrumb
          previousRoute="/a/settings"
          previousBreadcrumb="设置"
          currentPage="团队"
        />
        <BodyCard
          title="团队"
          subtitle="管理商城用户"
          actionables={actionables}
        >
          <div className="flex inter-large-semibold gap-x-base text-grey-40">
            {['member', 'role'].map((k, i) => (
              <div
                key={i}
                className={clsx("cursor-pointer", {
                  ["text-grey-90"]: k === activeView,
                })}
                onClick={() => {
                  // navigate(k==='member'?'/a/settings/team':'/a/settings/team/role')
                  setActiveView(k)
                }}
              >
                { showLabel(k) }
              </div>
            ))}
          </div>
          {
            activeView === 'member' ?
              <MemberTable
              userChangeNum={userChange}
                setUserChangeNum={() => setUserChange(prev => prev + 1)} /> :
              <RoleTable roleChangeNum={roleChange} setRoleChangeNum={() => setRoleChange(prev => prev + 1)} editRole={ toEditRole } />
          }

          {showInviteModal && (
            <InviteModal
              handleClose={() => {
                setShowInviteModal(false)
              }}
              refreshList={() => { 
                setUserChange(prev => prev + 1)
              }}
            />
          )}
          {showAddRoleModal && (
            <AddRoleModal
              handleClose={() => {
                setRoleId(null)
                setShowAddRoleModal(false)
              }}
              refreshList={() => {
                setRoleChange(prev => prev + 1)
              }}
              currRoleId={ roleId }
            />
          )}
        </BodyCard>
      </div>
    </div>
  )
}

export default Users
