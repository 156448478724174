import { graphql, useStaticQuery } from "gatsby"
import React, { useMemo, useState, useEffect, useContext } from "react";
import moment from "moment"
import { navigate } from "gatsby"
import Table, { TablePagination } from "../../../components/molecules/table"
import { usePagination, useTable } from "react-table"
import Tooltip from '../../../components/atoms/tooltip'
import { narrowTxt } from '../../../utils/dealText'
import CopyToClipboard from '../../../components/atoms/copy-to-clipboard'
import Spinner from '../../../components/atoms/spinner'
import Medusa from '../../../services/api'
import { priceAmount } from '../../../utils/prices'
import { AccountContext } from "../../../context/account";
import useNotification from "../../../hooks/use-notification";

const DEFAULT_PAGE_SIZE = 15

const AdminManageTable = ({ unit }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            networkUrl
          }
        }
      }
    `
  )
  const toEtherscan = (address) => { 
    if (address) { 
      if (window !== undefined) { 
        window.open(`${site.siteMetadata.networkUrl + address}`)
      }
    }
  }
  const account = useContext(AccountContext);
  const notification = useNotification();
  const columns = useMemo(
    () => [
      {
        Header: "链上交易ID",
        accessor: "id",
        Cell: ({ cell: { value } }) => (
          <div className="flex items-center" onClick={()=>toEtherscan(value)}>
            {value ? (
              <>
                {value.length > 14 ? (
                  <Tooltip content={value}>
                    <span className="mr-2 cursor-pointer text-[#007BD3]">{narrowTxt(value)}</span>
                  </Tooltip>
                ): (
                  <span className="mr-2 cursor-pointer text-[#007BD3]">{value}</span>
                ) }
              </>
            ):(<span>--</span>)}
            
          </div>
        ),
      },
      {
        Header: "时间",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        Header: "交易类型",
        accessor: "type",
        Cell: ({ cell: { value } }) => (
          <>
            {value===1?'收入':'支出' }
          </>
        ),
      },
      {
        Header: "付款账户",
        accessor: "from",
        Cell: ({ cell: { value } }) => (
          <div className="flex items-center">
            {value ? (
              <>
                {value.length > 14 ? (
                  <Tooltip content={value}>
                    <span className="mr-2 cursor-pointer">{narrowTxt(value)}</span>
                  </Tooltip>
                ): (
                  <span className="mr-2 cursor-pointer">{value}</span>
                ) }
                <CopyToClipboard
                  value={value}
                  showValue={false}
                  iconSize={14}
                />
              </>
            ):(<span>--</span>)}
          </div>
        ),
      },
      {
        Header: "收款账户",
        accessor: "to",
        Cell: ({ cell: { value } }) => (
          <div className="flex items-center">
            {value ? (
              <>
                {value.length > 14 ? (
                  <Tooltip content={value}>
                    <span className="mr-2 cursor-pointer">{narrowTxt(value)}</span>
                  </Tooltip>
                ): (
                  <span className="mr-2 cursor-pointer">{value}</span>
                ) }
                <CopyToClipboard
                  value={value}
                  showValue={false}
                  iconSize={14}
                />
              </>
            ):(<span>--</span>)}
          </div>
        ),
      },
      {
        Header: "订单",
        accessor: "orderId",
        Cell: ({ cell: { value } }) => (
          <div className="flex items-center" onClick={() => { 
            if (value) { 
              if (account.roles.length) { 
                let arr:any[] = [];
                account.roles.forEach((item) => {
                  arr = Array.from(new Set([...arr, ...item.permissions]));
                });
                if (arr.includes('1')) {
                  navigate(`/a/orders/${value}`)
                } else { 
                  notification("错误", "暂无访问权限，请联系管理员", "error");
                }
              }
            }
          }}>
            {value ? (
              <>
                {value.length > 14 ? (
                  <Tooltip content={value}>
                    <span className="mr-2 cursor-pointer text-[#007BD3]">{narrowTxt(value)}</span>
                  </Tooltip>
                ): (
                  <span className="mr-2 cursor-pointer text-[#007BD3]">{value}</span>
                ) }
              </>
            ):(<span>--</span>)}
            
          </div>
        ),
      },
      {
        Header: "金额",
        accessor: "value",
        Cell: ({ cell: { value, row } }) => priceAmount(value, row.original.currency)
      },
    ],
    []
  )
  
  const [numPages, setNumPages] = useState(0)
  const [count, setCount] = useState(0)
  const [tarnData, setTranData] = useState([])
  const [pageInfo, setPageInfo] = useState({
    limit: DEFAULT_PAGE_SIZE,
    offset: 0
  })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: tarnData || [],
      manualPagination: true,
      initialState: {
        pageSize: pageInfo.limit,
        pageIndex: pageInfo.offset / pageInfo.limit,
      },
      pageCount: numPages,
      autoResetPage: false,
    },
    usePagination
  )
  const getTransactionsLs = () => { 
    Medusa['adminManage']['transactions']({
      currency: unit,
      offset: pageInfo.offset,
      limit: pageInfo.limit
    }).then(res => {
      console.log(res)
      setTranData(res?.data?.data || [])
      const controlledPageCount = Math.ceil(res?.data?.count! / pageInfo.limit)
      setNumPages(controlledPageCount)
      setCount(res?.data?.count||0)
    })
  }
  const handleNext = () => {
    if (canNextPage) {
      setPageInfo({
        ...pageInfo,
        offset: pageInfo.offset + 1
      })
      nextPage()
    }
  }

  const handlePrev = () => {
    if (canPreviousPage) {
      setPageInfo({
        ...pageInfo,
        offset: pageInfo.offset - 1
      })
      previousPage()
    }
  }

  useEffect(() => { 
    setPageInfo({
      ...pageInfo,
      offset: 0
    })
    gotoPage(0)
    getTransactionsLs()
  }, [unit])
  useEffect(() => { 
    getTransactionsLs()
  }, [pageInfo])
  return (
    <div className="mt-[30px]">
      <div className="text-[#333] text-[16px] mb-[10px]">收支记录</div>
      <Table {...getTableProps()}>
        <Table.Head>
          {headerGroups?.map((headerGroup) => (
            <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((col, index) => (
                <Table.HeadCell className="w-[100px]" {...col.getHeaderProps()} key={ index }>
                  {col.render("Header")}
                </Table.HeadCell>
              ))}
            </Table.HeadRow>
          ))}
        </Table.Head>
        { !tarnData ? (
          <div className="absolute flex items-center justify-center w-full h-full mt-10">
            <div className="">
              <Spinner size={"large"} variant={"secondary"} />
            </div>
          </div>
        ) : (
          <Table.Body {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <Table.Row
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <Table.Cell {...cell.getCellProps()} key={ index }>
                        {cell.render("Cell", { index })}
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
        )}
      </Table>
      <TablePagination
        count={count!}
        limit={pageInfo.limit}
        offset={pageInfo.offset}
        pageSize={pageInfo.offset + rows.length}
        title="收支记录"
        currentPage={pageIndex + 1}
        pageCount={pageCount}
        nextPage={handleNext}
        prevPage={handlePrev}
        hasNext={canNextPage}
        hasPrev={canPreviousPage}
      />
    </div>
  )
}

export default AdminManageTable