import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../components/fundamentals/button"
import Input from "../../../components/molecules/input"
import Modal from "../../../components/molecules/modal"
import Select from "../../../components/molecules/select"

type AddressModalProps = {
  handleClose: () => void
  handleSave: ({ data, type }) => Promise<void>
  allowedCountries: string[]
  address?: object
  type: "shipping" | "billing"
}

const AddressModal: React.FC<AddressModalProps> = ({
  address,
  allowedCountries = [],
  handleClose,
  handleSave,
  type,
}) => {
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { ...address },
  })

  register("country_code")

  const countryOptions = allowedCountries
    .map((c) => ({ label: c.display_name, value: c.iso_2 }))
    .filter(Boolean)

  const [selectedCountry, setSelectedCountry] = useState(
    countryOptions.find((o) => o.value === address?.country_code)
  )

  const setCountry = (value) => {
    if (!value) {
      setSelectedCountry(undefined)
    } else {
      setSelectedCountry(value)
      setValue("country_code", value.value)
    }
  }

  const submit = (data) => {
    return handleSave({ data, type })
  }

  return (
    <Modal handleClose={handleClose}>
      <Modal.Body>
        <Modal.Header handleClose={handleClose}>
          <span className="inter-xlarge-semibold">
            {type === "billing" ? "发货" : "收货"} 地址
          </span>
        </Modal.Header>
        <Modal.Content>
          <div className="space-y-4">
            <span className="inter-base-semibold">基础信息</span>
            <div className="flex space-x-4">
              <Input
                label="名"
                name="first_name"
                placeholder="请输入"
                ref={register}
              />
              <Input
                label="姓"
                name="last_name"
                placeholder="请输入"
                ref={register}
              />
            </div>
            <div className="flex mt-4 space-x-4">
              <Input
                label="电话"
                name="phone"
                ref={register}
                placeholder="Phone"
              />
            </div>
            <div className="flex mt-4 space-x-4">
              <Input
                label="邮箱"
                name="email"
                ref={register}
                placeholder="Email"
              />
            </div>
          </div>
          <div className="mt-8 space-y-4">
            <span className="inter-base-semibold">地址</span>
            <div className="flex space-x-4">
              <Input
                label="地址"
                name="address_1"
                ref={register}
                placeholder="请输入"
              />
              <Input
                label="地址 2"
                name="address_2"
                ref={register}
                placeholder="请输入"
              />
            </div>
            <div className="flex space-x-4">
              <Input
                label="州/省"
                name="province"
                ref={register}
                placeholder="请输入"
              />
              <Input
                label="邮政编码"
                name="postal_code"
                ref={register}
                placeholder="请输入"
              />
            </div>
            <div className="flex space-x-4">
              <Input
                label="城市"
                name="city"
                ref={register}
                placeholder="请输入"
              />
              <Select
                ref={register}
                className="w-[180px]"
                name="country_code"
                label="国家"
                options={countryOptions}
                onChange={setCountry}
                value={selectedCountry}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex justify-end w-full h-8">
            <Button
              variant="ghost"
              className="justify-center w-32 mr-2 text-small"
              size="large"
              onClick={handleClose}
            >
              取消
            </Button>
            <Button
              size="large"
              className="justify-center w-32 text-small"
              variant="primary"
              onClick={handleSubmit(submit)}
            >
              保存
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default AddressModal
