import { useAdminCreateVariant } from "medusa-react"
import React, { useEffect, useState } from "react"
import Button from "../../../../components/fundamentals/button"
import EditIcon from "../../../../components/fundamentals/icons/edit-icon"
import PlusIcon from "../../../../components/fundamentals/icons/plus-icon"
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon"
import IconTooltip from "../../../../components/molecules/icon-tooltip"
import Input from "../../../../components/molecules/input"
import TagInput from "../../../../components/molecules/tag-input"
import BodyCard from "../../../../components/organisms/body-card"
import VariantGrid from "../../../../components/variant-grid"
import useNotification from "../../../../hooks/use-notification"
import { getErrorMessage } from "../../../../utils/error-messages"
import { getCombinations } from "../../../../utils/get-combinations"
import NewOption from "../../details/variants/option-edit"
import VariantEditor from "../../details/variants/variant-editor"
import { buildOptionsMap } from "../utils"
import RadioGroup from "../../../../components/organisms/radio-group"
import { Controller } from "react-hook-form"
import CurrencyInput from "../../../../components/organisms/currency-input"
import { numberOrNull } from "../../../../utils/form-helpers"
import {
  SINGLE_PRODUCT_VIEW,
  useProductForm,
  VARIANTS_VIEW,
} from "../form/product-form-context"
import usePricesFieldArray from "../form/usePricesFieldArray"

const Variants = ({ isEdit, product }) => {
  const {
    setValue,
    setVariants,
    variants,
    productOptions,
    setProductOptions,
    setViewType,
    viewType,
    isVariantsView,
    register,
    control
  } = useProductForm()
  const [showAddVariantModal, setShowAddVariantModal] = useState(false)
  const [showAddOption, setShowAddOption] = useState(false)
  const notification = useNotification()
  const createVariant = useAdminCreateVariant(product?.id)

  const fields = [{
    indexId: 'usd-1000',
    price: {
      amount: 1000,
      currency_code: 'usd'
    }
  }]
  useEffect(() => {
    if (isEdit) {
      return
    }

    const os = [...productOptions]
    const combinations = getCombinations(os)

    const newVariants = combinations.map((optionValues) => {
      if (!optionValues) {
        return null
      }

      const existing = variants.find((v) =>
        v.options.every((value, index) => optionValues[index] === value)
      ) || { prices: [] }

      existing.options = optionValues.filter((v) => v !== "")

      return existing
    })

    setVariants(newVariants.filter((v) => !!v))
  }, [productOptions])

  const updateOptionValue = (index, values) => {
    const newOptions = [...productOptions]
    newOptions[index] = {
      ...newOptions[index],
      values,
    }

    setValue(`options[${index}].values`, values)
    setProductOptions(newOptions)
  }

  const handleRemoveOption = (index) => {
    const newOptions = [...productOptions]
    newOptions.splice(index, 1)
    setProductOptions(newOptions)
  }

  const handleAddOption = (e) => {
    setProductOptions([
      ...productOptions,
      {
        name: "",
        values: [],
      },
    ])
  }

  const updateOptionName = (e, index) => {
    const element = e.target
    const newOptions = [...productOptions]
    newOptions[index] = {
      ...newOptions[index],
      name: element.value,
    }

    setValue(`options[${index}].name`, element.value)
    setProductOptions(newOptions)
  }

  const handleAddVariant = (data) => {
    const newVariant = {
      ...data,
      inventory_quantity: data.inventory_quantity || 0,
    }

    createVariant.mutate(newVariant, {
      onSuccess: () => {
        notification("成功", "新增规格成功", "success")
        setShowAddVariantModal(false)
      },
      onError: (err) => {
        notification("失败", getErrorMessage(err), "error")
      },
    })
  }

  return (
    <BodyCard
      title="产品类型"
      subtitle=""
      forceDropdown={true}
      actionables={
        isEdit && [
          {
            label: "添加规格",
            onClick: () => setShowAddVariantModal(true),
            icon: <PlusIcon size={20} />,
          },
          {
            label: "编辑选项",
            onClick: () => setShowAddOption(true),
            icon: <EditIcon size={20} />,
          },
        ]
      }
    >
      {
        !isEdit && (
          <RadioGroup.Root
            value={viewType}
            onValueChange={setViewType}
            className="flex items-center gap-4 mb-6"
          >
            <RadioGroup.SimpleItem
              label="一种规格"
              value={SINGLE_PRODUCT_VIEW}
            />
            <RadioGroup.SimpleItem
              label="多种规格"
              value={VARIANTS_VIEW}
            />
          </RadioGroup.Root>
        )
      }
      {
        isVariantsView ? (
          <div>
            {!isEdit && (
              <>
                <div className="flex items-center mb-base">
                  <h6 className="inter-base-semibold text-grey-90 mr-1.5">
                    产品选项
                  </h6>
                </div>
                <div className="flex flex-col gap-y-base max-w-[570px] w-full mb-4">
                  {productOptions.map((o, index) => (
                    <div key={index} className="flex items-center">
                      <div className="flex gap-x-small grow">
                        <Input
                          required
                          className="w-[144px]"
                          name={`options[${index}].name`}
                          onChange={(e) => updateOptionName(e, index)}
                          label="选项标题"
                          placeholder="选项值：如颜色..."
                          value={o?.name || o.title}
                        />
                        <TagInput
                          className="grow"
                          placeholder="请输入值"
                          values={o?.values}
                          onChange={(values) => updateOptionValue(index, values)}
                        />
                      </div>
                      <button
                        className="ml-large"
                        onClick={() => handleRemoveOption(index)}
                      >
                        <TrashIcon className="text-grey-40" />
                      </button>
                    </div>
                  ))}
                  <div className="mt-xs">
                    <Button onClick={handleAddOption} size="small" variant="ghost">
                      + 添加选项
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col justify-center space-y-2 mb-base">
                  <div className="flex space-x-2">
                    <h6 className="inter-base-semibold text-grey-90">规格</h6>
                    <IconTooltip content="添加产品选项以创建规格" />
                  </div>
                </div>
              </>
            )}
            <VariantGrid
              edit={isEdit}
              product={product}
              variants={variants}
              onVariantsChange={(vars) => setVariants(vars)}
            />
          </div>
        ) : (
          <div className="max-w-[630px]">
              {fields.map((field, index) => {
                return (
                <>
                  <div className="w-full flex inter-small-semibold text-[#101010] items-center mb-2">
                    <label>价格</label>
                    <div className="text-rose-50">*</div>
                  </div>
                  <div
                    key={field.indexId}
                    className="flex items-center last:mb-0 mb-xsmall"
                  >
                    <div className="flex-1">
                      <Controller
                        control={control}
                        key={field.indexId}
                        name={`prices[${index}].price`}
                        ref={register()}
                        defaultValue={field.price}
                        render={({ onChange, value }) => {
                          const codes = [
                            value?.currency_code,
                          ]
                          codes.sort()
                          return (
                            <CurrencyInput
                              currencyCodes={codes}
                              currentCurrency={value?.currency_code}
                              size="medium"
                              readOnly={index === 0}
                              onChange={(code) =>
                                onChange({ ...value, currency_code: code })
                              }
                            >
                              <CurrencyInput.AmountInput
                                label="价格"
                                onChange={(amount) =>
                                  onChange({ ...value, amount })
                                }
                                amount={value?.amount}
                              />
                            </CurrencyInput>
                          )
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-full flex inter-small-semibold text-[#101010] items-center mb-2">
                    <label>其他</label>
                  </div>
                  <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-large">
                    <Input
                      label="SKU"
                      name="sku"
                      placeholder="SUN-G, JK1234..."
                      ref={register}
                    />
                    <Input
                      label="EAN"
                      name="ean"
                      placeholder="1231231231234..."
                      ref={register}
                    />
                    <Input
                      label="库存数量"
                      name="inventory_quantity"
                      type="number"
                      placeholder="100"
                      ref={register({ setValueAs: numberOrNull })}
                    />
                  </div>
                </>
              )
            })}
          </div>
        )
      }
      
      {showAddOption && (
        <NewOption
          productId={product.id}
          options={product.options}
          onDismiss={() => setShowAddOption(false)}
        />
      )}
      {showAddVariantModal && (
        <VariantEditor
          onCancel={() => setShowAddVariantModal(false)}
          onSubmit={handleAddVariant}
          title="添加规格"
          optionsMap={buildOptionsMap(product)}
        />
      )}
    </BodyCard>
  )
}

export default Variants
