import React from 'react';
import { styled } from '@stitches/react';
import { violet, mauve, blackA, gray } from '@radix-ui/colors';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import * as RadixSelect from "@radix-ui/react-select"

const StyledTrigger = styled(RadixSelect.SelectTrigger, {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 4,
  padding: '0 15px',
  fontSize: 13,
  lineHeight: 1,
  height: 32,
  gap: 5,
  border: `1px solid ${gray.gray4}`,
  backgroundColor: 'white',
  color: violet.violet11,
  width: 150,
  position: 'relative',
  '&:hover': { backgroundColor: mauve.mauve3 },
  '&:focus': { boxShadow: `0 0 0 1px black` },
  '&[data-placeholder]': { color: violet.violet9 },
});

const StyledIcon = styled(RadixSelect.SelectIcon, {
  color: violet.violet11,
});

const StyledContent = styled(RadixSelect.Content, {
  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const StyledViewport = styled(RadixSelect.Viewport, {
  padding: 5,
});

function Content({ children, ...props }) {
  return (
    <RadixSelect.Portal>
      <StyledContent {...props}>{children}</StyledContent>
    </RadixSelect.Portal>
  );
}

const StyledItem = styled(RadixSelect.Item, {
  all: 'unset',
  fontSize: 13,
  lineHeight: 1,
  color: violet.violet11,
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  height: 25,
  padding: '0 35px 0 25px',
  position: 'relative',
  userSelect: 'none',

  '&[data-disabled]': {
    color: mauve.mauve8,
    pointerEvents: 'none',
  },

  '&[data-highlighted]': {
    backgroundColor: violet.violet9,
    color: violet.violet1,
  },
});

const StyledLabel = styled(RadixSelect.Label, {
  padding: '0 25px',
  fontSize: 12,
  lineHeight: '25px',
  color: mauve.mauve11,
});

const StyledSeparator = styled(RadixSelect.Separator, {
  height: 1,
  backgroundColor: violet.violet6,
  margin: 5,
});

const StyledItemIndicator = styled(RadixSelect.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  color: violet.violet11,
  cursor: 'default',
};

const StyledScrollUpButton = styled(RadixSelect.ScrollUpButton, scrollButtonStyles);

const StyledScrollDownButton = styled(RadixSelect.ScrollDownButton, scrollButtonStyles);

// Exports
export const Select = RadixSelect.Root;
export const SelectTrigger = StyledTrigger;
export const SelectValue = RadixSelect.Value;
export const SelectIcon = StyledIcon;
export const SelectContent = Content;
export const SelectViewport = StyledViewport;
export const SelectGroup = RadixSelect.Group;
export const SelectItem = StyledItem;
export const SelectItemText = RadixSelect.ItemText;
export const SelectItemIndicator = StyledItemIndicator;
export const SelectLabel = StyledLabel;
export const SelectSeparator = StyledSeparator;
export const SelectScrollUpButton = StyledScrollUpButton;
export const SelectScrollDownButton = StyledScrollDownButton;

// Your app...
const Box = styled('div', {});

export const SelectDemo = ({ changeValue, options, val, dval }) => {
  return (
    <Box>
      <Select defaultValue={dval||undefined} value={val} onValueChange={(val) => changeValue(val)}>
        <SelectTrigger>
          <SelectValue placeholder="请选择" />
          <SelectIcon>
            <ChevronDownIcon />
          </SelectIcon>
        </SelectTrigger>
        <SelectContent>
          <SelectScrollUpButton>
            <ChevronUpIcon />
          </SelectScrollUpButton>
          <SelectViewport>
            <SelectGroup>
              {
                options.map(item => (
                  <>
                    <SelectItem value={item.value}>
                      <SelectItemText>{item.label}</SelectItemText>
                      <SelectItemIndicator>
                        <CheckIcon />
                      </SelectItemIndicator>
                    </SelectItem>
                  </>
                ))
              }
            </SelectGroup>
          </SelectViewport>
          <SelectScrollDownButton>
            <ChevronDownIcon />
          </SelectScrollDownButton>
        </SelectContent>
      </Select>
    </Box>
  );
}
export default SelectDemo;
