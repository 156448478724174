import moment from "moment"
import React, { useMemo } from "react"
import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import CopyToClipboard from "../../atoms/copy-to-clipboard"
import { getColor } from "../../../utils/color"
import { narrowTxt } from '../../../utils/dealText'
import CustomerAvatarItem from "../../molecules/customer-avatar-item"

export const useCustomerColumns = (toSetEditInfo) => {
  const columns = useMemo(
    () => [
      {
        Header: "添加日期",
        accessor: "created_at", // accessor is the "key" in the data
        Cell: ({ cell: { value } }) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        Header: "钱包地址",
        accessor: "wallet_address",
        Cell: ({ cell: { value } }) => (
          <div className="flex items-center">
            {value ? (
              <>
                {value.length > 14 ? (
                  <Tooltip placement="top" title={value}>
                    <span className="mr-2">{narrowTxt(value)}</span>
                  </Tooltip>
                ): (
                  <span className="mr-2">{value}</span>
                ) }
                <CopyToClipboard
                  value={value}
                  showValue={false}
                  iconSize={14}
                />
              </>
            ):(<span>--</span>)}
            
          </div>
        )
      },
      {
        Header: "推特",
        accessor: "twitter",
        Cell: ({ cell: { value } }) => (
        <>
          {value?(<div className="flex items-center">
              {value.length > 14 ? (
                <Tooltip placement="top" title={value}>
                  <span className="mr-2">{narrowTxt(value)}</span>
                </Tooltip>) :
                (<span className="mr-2">{value}</span>)}
            
            <CopyToClipboard
              value={value}
              showValue={false}
              iconSize={14}
            />
          </div >):(<span>--</span>)}
        </>)
      },
      // {
      //   Header: "Name",
      //   accessor: "customer",
      //   Cell: ({ row }) => (
      //     <CustomerAvatarItem
      //       customer={row.original}
      //       color={getColor(row.index)}
      //     />
      //   ),
      // },
      // {
      //   Header: "Email",
      //   accessor: "email",
      // },
      // {
      //   Header: "",
      //   accessor: "col",
      // },
      {
        accessor: "orders",
        Header: () => <div className="text-left">订单数量</div>,
        Cell: ({ cell: { value } }) => (
          <div className="text-left">{value?.length || 0}</div>
        ),
      },
      {
        Header: "备注",
        accessor: "remark",
        Cell: ({ row }) => (
          <div className="flex items-center">
            {
              (row.original.remark && row.original.remark.length > 14) ? (
                <Tooltip placement="top" title={row.original.remark}>
                  <span className="mr-2">{narrowTxt(row.original.remark)}</span>
                </Tooltip>
              ):(<span className="mr-2">{narrowTxt(row.original.remark)}</span>)
            }
            <EditOutlined size={14} onClick={(e) => {
              e.stopPropagation()
              toSetEditInfo(row.original)
            }} />
          </div>
        )
      },
      {
        Header: "",
        accessor: "col-2",
      },
    ],
    []
  )

  return [columns]
}
